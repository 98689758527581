<template>
  <div>
    <CRow>
      <CCol md="12" lg="12" xl="12" xxl="12" xs="12" sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de productos"
          icon="fas fa-box"
          btn_name="Producto"
          @mtdBack="mtdBack"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'3%'"
          :buttonEdit="true"
          :buttonDelete="true"
          :myButtons="myButtons"
          @mtd_prices="mtd_prices"
          @mtdGetBarcode="mtdGetBarcode"
        />
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
      :color="'info'"
      :centered="true"
    >
      <CRow>
        <CCol sm="12">
          <CInput
            label="Nombre"
            placeholder="Digite nombre"
            v-model="product.name"
          />
        </CCol>
        <CCol sm="12">
          <CInputRadioGroup
            class="col-sm-12"
            :options="['CAMAL', 'TIENDA','DERIVADO']"
            :inline="true"
            :checked.sync="product.type"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="'Borrar Producto'"
      :show.sync="modal_delete.boo"
      @mtd_commit="mtd_commit"
      :centered="true"
      :item="modal_delete.item"
    />

    <!-- modal edit price-->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'lg'"
      :color="'info'"
      :centered="true"
      :title="modal_price.title"
      @mtd_action="mtd_prices_edit"
      :button="false"
      :show.sync="modal_price.boo"
    >
      <CRow class="mt-1">
        <CCol lg="12" xl="12" md="12" sm="12" xs="12">
          <div class="position-relative table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Tipo de producto</th>
                  <th>Prec. costo</th>
                  <th>Prec. minorista</th>
                  <th>Prec. mayorista</th>
                  <th>Prec. especial</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in modal_price.prices" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.type == null ? "Tienda" : item.type.name }}</td>
                  <td>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="item.price_cost"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="item.price_sale_min"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="item.price_sale_may"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="item.price_sale_otro"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal codes -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'lg'"
      :title="modalBarCode.title"
      :show.sync="modalBarCode.boo"
      @mtd_action="mtd_action"
      :color="'info'"
      :centered="true"
      :show_boton="false"
    >
      <CRow>
        <CCol xl="9" lg="9" md="12" xs="12" sm="12">
          <CInput
            label="Nuevo código"
            placeholder="Digite código"
            v-model="modalBarCode.code"
          />
        </CCol>
        <CCol xl="3" lg="3" md="12" xs="12" sm="12">
          <button
            class="btn btn-sm btn-info w-100 mt-4"
            @click="mtdSaveBarcode"
            :disabled="cpButtonAddCode"
          >
            <i class="fa fa-save"></i> Guardar
          </button>
        </CCol>
        <CCol sm="12" xs="12" md="12" lg="12" xl="12">
          <div class="position-relative table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Codigo</th>
                  <th>Borrar</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in modalBarCode.codes" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.name }}</td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-sm btn-danger"
                      @click="mtdDelItem(index, item.id)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCol>
      </CRow>
    </CModalForm>
  </div>
</template>
  
  <script>
const fields = [
  { key: "id", label: "Id", _style: "width:1%" },
  { key: "name", label: "Nombre", _style: "width:20%;" },
  { key: "type", label: "Tipo", _style: "width:5%;" },
  { key: "state", label: "Estado", _style: "width:5%;" },
  // {
  //   key: 'show_details',
  //   label: 'Detalle',
  //   _style: 'min-width:1%'
  // },
];

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  components: { CTableWrapper, CModalForm, cModalDelete },
  data() {
    return {
      prefix: "product",
      fields,
      data: [],
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      product: {
        id: "",
        name: "",
        type: "",
      },
      type: "",
      modal_delete: {
        boo: false,
        item: [],
      },
      data_price: [],
      modal_price: {
        boo: false,
        title: "",
        prices: [],
      },
      myButtons: [
        {
          class: "btn-sm",
          color: "success",
          tooltip: "precios",
          action: "mtd_prices",
          icon: "fas fa-dollar-sign",
          // play: {
          //   type: "type_id_add_alternativas", //resource
          //   1: true,
          //   2: false,
          // },
        },
        {
          class: "btn-sm",
          color: "warning",
          tooltip: "Cod barras",
          action: "mtdGetBarcode",
          icon: "fas fa-barcode",
          // play: {
          //   type: "type_id_add_alternativas", //resource
          //   1: true,
          //   2: false,
          // },
        },
      ],
      modalBarCode: {
        boo: false,
        codes: [],
        product_id: 0,
        title: "",
        code: "",
      },
    };
  },
  computed: {
    cp_button: function () {
      if (this.product.name != "" && this.product.type != "") return false;
      return true;
    },
    cpButtonAddCode: function () {
      if (this.modalBarCode.code.length > 0) return false;
      return true;
    },
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/product",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.products;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nuevo Producto")
        : (this.modal.title = "Editar Producto");
      action == "store"
        ? (this.product = {
            id: "",
            name: "",
            type: "",
            state: "",
          })
        : (this.product = this.product);
    },
    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.product,
      })
        .then((response) => {
          let color = "success";
          let message = "";
          if (this.modal.action == "store") {
            this.data.push(response[0]);
            message = "REGISTRADO CORRECTAMENTE";
            this.mtd_getdata();
          } else {
            this.data.forEach((element) => {
              if (element.id == response.data[0].id) {
                element.name = response.data[0].name;
                element.type = response.data[0].type;
              }
              message = "EDITADO CORRECTAMENTE";
            });
          }
          this.modal = {
            action: "",
            title: "",
            modal_form: false,
            name: "",
          };
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.product = response;
          this.product.type = this.product.type == 2 ? "TIENDA" : (this.product.type == 1?"CAMAL":"DERIVADO");
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {});
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.id != response.id) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete = {
            boo: false,
            item: [],
          };
          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {});
    },
    /*** modal prices */
    mtd_prices: function (item) {
      this.get({
        url: this.$store.getters.get__url + "/product/" + item.id + "/getPrice",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.modal_price.prices = response;
          //Activar Modal Precio
          this.modal_price.boo = true;
          this.modal_price.title = "Editar precios de: " + item.name;
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_prices_edit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/editPrice",
        token: this.$store.getters.get__token,
        params: {
          prices: this.modal_price.prices,
        },
      })
        .then((response) => {
          if (response.state == 0) {
            this.modal_price.boo = false;
            bus.$emit("alert", {
              color: "success",
              message: "Precios actualizados correctamente",
            });
          } else {
            bus.$emit("alert", {
              color: "success",
              message: "Erorr al actualizar",
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: "Error al actualizar los precios",
          });
        });
    },
    /*** codigos de barras */
    mtdGetBarcode: function (item) {
      this.modalBarCode.boo = true;
      this.modalBarCode.codes = item.codes;
      this.modalBarCode.product_id = item.id;
      this.modalBarCode.title = "CÓDIGOS DE BARRAS";
    },
    mtdSaveBarcode: function () {
      this.post({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/store/barcode",
        token: this.$store.getters.get__token,
        params: this.modalBarCode,
      })
        .then((response) => {
          this.modalBarCode.codes.push(response);
          this.modalBarCode.name = "";
        })
        .catch((errors) => {});
    },
    mtdDelItem: function (pos, id) {
      this.post({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/destroy/barcode",
        token: this.$store.getters.get__token,
        params: {
          id: id,
        },
      })
        .then((response) => {
          let temp = [];
          this.modalBarCode.codes.forEach((element, index) => {
            if (element.id != id) {
              temp.push(element);
            }
          });
          this.modalBarCode.codes = temp;
          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {});
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.btn-search {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}
</style>