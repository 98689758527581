<template>
  <div>
    <CRow class="mt-1">
      <CCol lg="3" xl="3" md="12" sm="12" xs="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-dolly"></i>&nbsp; MIS DESPACHOS</span>
            <button
              @click="mtdBack"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="12" class="text-center" v-if="(dispatch.length === 0)">
                <h4>NO HAY DESPACHOS REALIZADOS</h4>
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12">
                <ul class="list-group">
                  <li
                    v-for="(item, index) in dispatch"
                    :key="index"
                    :class="mtdGetClasesDispatch(item.state, index)"
                    @click="mtdSelectDispatch(item, index)"
                  >
                    T001-{{ item.id }}
                  </li>
                </ul>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol v-if="selectDispatch != null" lg="5" xl="5" md="12" sm="12" xs="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span
              ><i class="fas fa-search-dollar"></i>&nbsp; DATOS DE LA
              VENTA</span
            >
          </CCardHeader>
          <CCardBody>
            <CRow v-for="(item, index) in cabSale.detail" :key="index">
              <CCol xs="12" sm="12" md="12" lg="12" class="text-center"
                ><b>{{ item.product_name }} </b></CCol
              >
              <CCol xs="12" sm="12" md="12" lg="12" v-for="(it, ind) in item.detail" :key="ind">
                <CRow class="mt-1">
                  <CCol xs="12" sm="12" md="4" lg="4" xl="4">
                    <b>{{ it.type }}</b>
                    <!-- <span> ({{ item}}Kgf)</span> -->
                    <span>
                      ({{ parseFloat(it.neto_kg - it.sale) }}Kg)</span
                    >
                  </CCol>
                  <CCol xs="12" sm="12" md="4" lg="4" xl="4">
                    <div class="btn-group btn-group-sm" role="group">
                      <!--Boton precio minimo-->
                      <button 
                        :class="
                          it.isSelectedPrice == it.prices[0].price_sale_min
                          ? 'btn btn-sm btn-warning' 
                          : 'btn btn-sm btn-outline-warning'
                        "
                        @click="mtdSelectPrice(it.prices[0].price_sale_min,ind,index)"
                      >
                        S/{{ it.prices[0].price_sale_min }}
                      </button>
                      <!--Boton precio maximo-->
                      <button 
                        :class="
                          it.isSelectedPrice == it.prices[0].price_sale_may
                          ? 'btn btn-sm btn-success' 
                          : 'btn btn-sm btn-outline-success'
                        "
                        @click="mtdSelectPrice(it.prices[0].price_sale_may,ind,index)"
                      >
                        S/{{ it.prices[0].price_sale_may }}
                      </button>
                      <!--Boton precio otro-->
                      <button 
                        :class="
                          it.isSelectedPrice == it.prices[0].price_sale_otro
                          ? 'btn btn-sm btn-primary' 
                          : 'btn btn-sm btn-outline-primary'
                        "
                        @click="mtdSelectPrice(it.prices[0].price_sale_otro,ind,index)"
                      >
                        S/{{ it.prices[0].price_sale_otro }}
                      </button>
                    </div>
                  </CCol>
                  <CCol xs="12" sm="12" md="4" lg="4" xl="4">
                    <CInput
                      label=""
                      size="sm"
                      placeholder="Cantidad"
                      v-model="it.quantity"
                      v-on:keypress="isNumber($event)"
                      :disabled="it.neto_kg > 0 ? false : true"
                      @keyup="
                        mtdVerifQuantity(
                          it.sale,
                          it.quantity,
                          it.neto_kg,
                          index,
                          ind
                        )
                      "
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol v-if="selectDispatch != null" lg="4" xl="4" md="12" sm="12" xs="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span
              ><i class="fas fa-comment-dollar"></i>&nbsp; COBRO DE LA
              VENTA</span
            >
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol
                lg="12"
                xl="12"
                md="12"
                sm="12"
                xs="12"
                class="text-center text-bold"
              >
                <h5>Total a pagar: S/{{ this.cabSale.total }}</h5>
              </CCol>
              <CCol
                lg="4"
                xl="4"
                md="12"
                sm="12"
                xs="12"
                class="d-flex justify-content-evenly"
                v-for="(item, index) in method"
                :key="index"
                @click="mtdSelectMethod(item.id)"
              >
                <CButton
                  :disabled="!activarBtn"
                  :class="
                    cabSale.method == item.id
                      ? 'w-100 btn btn-sm btn-success'
                      : 'w-100 btn btn-sm btn-outline-success'
                  "
                >
                  <i class="fas fa-donate"></i> {{ item.name }}
                </CButton>
              </CCol>
              <CCol
                lg="12"
                xl="12"
                md="12"
                sm="12"
                xs="12"
                class="text-bold mt-2"
              >
                <CInput
                  label="Num operación"
                  v-model="cabSale.numOpe"
                  placeholder="Número de operación"
                  :disabled="disableOpe"
                />
              </CCol>
              <CCol
                lg="12"
                xl="12"
                md="12"
                sm="12"
                xs="12"
                class="text-center text-bold"
              >
                <CInputCheckbox
                    :label="'Crédito'"
                    :checked="cabSale.isChecked"
                    @change="mtdChangeType"
                  />
              </CCol>
              <CCol
                lg="12"
                xl="12"
                md="12"
                sm="12"
                xs="12"
                class="text-bold mt-2"
              >
                <CInput
                  label="Amortización"
                  v-model="cabSale.amortizacion"
                  placeholder="Amortización"
                  :disabled="!cabSale.isChecked"
                />
              </CCol>
            </CRow>
            <hr />
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="12" class="text-center"
                ><b>CLIENTE</b></CCol
              >
              <CCol xs="12" sm="12" md="6" lg="6">
                <CInput
                  label="Dni"
                  v-model="cabSale.client.dni"
                  placeholder="dni"
                  maxlength="8"
                  v-on:keypress="isNumber($event)"
                  @keyup="mtdSearchClient"
                />
              </CCol>
              <CCol xs="12" sm="12" md="6" lg="6">
                <!-- :disabled="disabledCel" disabled name -->
                <CInput
                  label="Celular"
                  v-model="cabSale.client.cellphone"
                  placeholder="celular"
                  maxlength="9"
                  v-on:keypress="isNumber($event)"
                />
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12">
                <CInput
                  label="Nombre"
                  v-model="cabSale.client.name"
                  placeholder="Nombre de cliente"
                  :disabled="disabledClient"
                />
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12">
                <button class="btn btn-info btn-sm w-100" @click="mtdVerifSale">
                  <i class="fas fa-save"></i> Guardar
                </button>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  name: "c-proccess-sale",
  data() {
    return {
      dispatch: [],
      method: [],
      keyDispatchSelected: null,
      selectDispatch: null,
      disabledClient: false,
      disabledCel: false,
      disableOpe: true,
      disableAmortizacion: true,
      // verif: false,
      cabSale: {
        distpach_id: "",
        dealer_id: "",
        client: {
          dni: "",
          name: "",
          cellphone: "",
        },
        method: 0,
        numOpe: "",
        amortizacion: 0,
        total: 0,
        detail: [],
        isChecked: false, // false contado, true credito
      },
    };
  },
  created() {
    this.mtdGetdata();
  },
  computed: {
    cpCheck() {},
    activarBtn() {
      if (this.cabSale.isChecked) {
        return this.cabSale.amortizacion > 0;
      }
      return true;
    },
  },
  watch: {
    'cabSale.amortizacion': function(newVal) {
      if (this.cabSale.isChecked) {
        this.disableOpe = newVal <= 0;
        if (newVal <= 0) {
          this.cabSale.method = 0;
          this.cabSale.numOpe = "";
        }
      } else {
        this.disableOpe = false;
      }
    }
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtdGetdata: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/process/sale/" +
          this.$store.getters.get_campu.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.dispatch = response.data.dispatch;
          this.method = response.data.method;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdGetClasesDispatch: function (state, pos) {
      let clases = "";
      if (pos == this.keyDispatchSelected) {
        clases = "list-group-item list-group-item-action active";
      } else {
        switch (state) {
          case 1:
            clases = "list-group-item list-group-item-primary";
            break;
          default:
            break;
        }
      }
      return clases;
    },
    mtdSelectDispatch: function (payload, pos) {
      this.keyDispatchSelected = pos;
      this.selectDispatch = payload;
      this.cabSale.distpach_id = payload.id;
      this.cabSale.dealer_id = payload.dealer_id;
      this.cabSale.isChecked=false;
      this.cabSale.client = {
        dni: "",
        name: "",
        cellphone: "",
      };
      this.cabSale.detail = [];
      payload.detail.forEach((element, ind) => {
        this.cabSale.detail.push({
          product_id: element.product.id,
          product_name: element.product.name,
        });
        this.cabSale.detail[ind].detail = [];
        let det = JSON.parse(element.detail);
        det.forEach((ele, index) => {
          if (index > 0) {
            let tipo = ele.type == 'VIVO' ? 1 : 2; 
            let arPrices = element.product.prices.filter(price => price.type_id == tipo);
            this.cabSale.detail[ind].detail.push({
              type: ele.type,
              sale: ele.type == 'VIVO' ? element.sale_vivo : element.sale_bene,
              neto_kg: ele.neto_kg,
              quantity: 0,
              prices: arPrices,
              isSelectedPrice: null,
            });
          }
        });
      });
      console.log(this.cabSale);
    },
    mtdVerifQuantity: function (venta, cantidad, neto, posCab, pos) {
      let pendiente = parseFloat(neto - venta);
      if (parseFloat(pendiente) < parseFloat(cantidad)) {
        // this.cabSale.detail[posCab].detail[pos].quantity = 0;
        bus.$emit("alert", {
          color: "warning",
          message: "La cantidad no es valida!",
        });
      } else {
        let tempTot = 0;
        this.cabSale.detail.forEach((element) => {
          element.detail.forEach((ele) => {
            let sb = parseFloat(ele.quantity * ele.isSelectedPrice);
            tempTot += sb;
          });
        });
        this.cabSale.total = parseFloat(tempTot).toFixed(2);
      }
    },
    mtdSearchClient: function () {
      if (this.cabSale.client.dni.length == 8) {
        this.get({
          url:
            this.$store.getters.get__url +
            "/document/" +
            this.cabSale.client.dni,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            if (response.boo == 0 || response.boo == 2) {
              let app =
                response.last_name == undefined ? "" : response.last_name;
              let fullName = response.name + (app.length > 0 ? " " + app : "");
              this.cabSale.client.name = fullName;
              this.cabSale.client.cellphone =
                response.celphone != null ? response.celphone : "";
              this.disabledClient = true;
              this.disabledCel = response.celphone != null ? false : false;
              bus.$emit("alert", {
                color: "success",
                message: "Documento encontrado",
              });
            } else {
              bus.$emit("alert", {
                color: "danger",
                message: response.message,
              });
            }
          })
          .catch((errors) => {
            // this.errorsBackend = errors;
            // this.$emit("error", this.event);
          });
      }
    },
    mtdVerifSale: function () {
      if (this.cabSale.total == 0.0) {
        bus.$emit("alert", {
          color: "warning",
          message: "El total debe ser mayor a cero!",
        });
      } else {
        if (this.cabSale.isChecked) {
          if (
              this.cabSale.client.dni.length > 0 &&
              this.cabSale.client.name.length > 0 &&
              this.cabSale.client.cellphone.length > 0
            ) {
              this.mtdSaveSale();
            } else {
              bus.$emit("alert", {
                color: "warning",
                message: "Complete los datos del cliente!",
              });
            }
        }else{
          if (this.cabSale.method == 0) {
            bus.$emit("alert", {
              color: "warning",
              message: "Escoja un método de pago!",
            });
          } else {
            if (
              this.cabSale.client.dni.length > 0 &&
              this.cabSale.client.name.length > 0 &&
              this.cabSale.client.cellphone.length > 0
            ) {
              this.mtdSaveSale();
            } else {
              bus.$emit("alert", {
                color: "warning",
                message: "Complete los datos del cliente!",
              });
            }
          }
        }        
      }
    },
    mtdSelectMethod: function (method) {
      if (this.activarBtn) {
        this.cabSale.method = method;
        this.disableOpe = !(method === 2 || method === 3);
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    mtdSaveSale: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/process/sale/store/" +
          this.$store.getters.get_campu.id,
        token: this.$store.getters.get__token,
        params: this.cabSale,
      })
        .then((response) => {
          if (!response.error) {
            this.cabSale = {
              distpach_id: "",
              dealer_id: "",
              client: {
                dni: "",
                name: "",
                cellphone: "",
              },
              method: 0,
              numOpe: "",
              amortizacion: 0,
              total: 0,
              detail: [],
            };
            (this.keyDispatchSelected = null),
              (this.selectDispatch = null),
              (this.disabledClient = false),
              (this.disabledCel = false),
              (this.disableOpe = true),
              this.mtdGetdata();
            window.open(
              this.$store.getters.get__url + "/ticket/sale/" + response.data.id,
              "_blank"
            );
            bus.$emit("alert", {
              color: "success",
              message: "Venta registrada!!",
            });
          } else {
            bus.$emit("alert", {
              color: "warning",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdChangeType: function (event) {
      this.cabSale.isChecked = event.target.checked;
      if (!this.cabSale.isChecked) {
        this.cabSale.amortizacion = 0;
        this.disableOpe = false;
      } else {
        this.disableOpe = true;
        this.cabSale.method = 0;
        this.cabSale.numOpe = "";
      }
    },
    mtdSelectPrice: function (value,ind,index) {
      this.cabSale.detail[index].detail[ind].isSelectedPrice = value;
      let it = this.cabSale.detail[index].detail[ind];
      // console.log(this.cabSale.detail[index].detail[ind].isSelectedPrice);
      this.mtdVerifQuantity(it.sale,it.quantity, it.neto_kg,index,ind);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>