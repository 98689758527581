<template>
  <div>
    <CRow class="mt-1">
      <CCol lg="4" xl="4" md="12" sm="12" xs="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-dolly"></i>&nbsp; COMPRAR PRODUCTOS</span>
            <button
              @click="mtdBack"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="12">
                <cSelectForm
                  label="Seleccione un proveedor"
                  :options="dataProviders"
                  placeholder="Escoja un proveedor"
                  :value.sync="purchase.provider"
                />
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12">
                <CInput
                  label="N°. Factura"
                  placeholder="Digite n° de documento"
                  v-model="purchase.factura"
                />
              </CCol>
            </CRow>
            <h3>Detalle de pedido</h3>
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="12">
                <CListGroup>
                  <CListGroupItem
                    v-for="(item, index) in dataProducts"
                    :key="index"
                    :color="mtdGetActive(item.label)"
                    @click="mtdSelectProduct(item)"
                    >{{ item.label }}</CListGroupItem
                  >
                </CListGroup>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol lg="8" xl="8" md="12" sm="12" xs="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-bars"></i>&nbsp; DETALLE DE COMPRA</span>
          </CCardHeader>
          <CCardBody>
            <CRow v-if="purchase.detail.length > 0">
              <CCol lg="12" xl="12" md="12" sm="12" xs="12">
                <div class="position-relative table-responsive">
                  <table class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Precio Compra</th>
                        <th>Subtotal</th>
                        <th>Eliminar</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in purchase.detail" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.name }}</td>
                        <td>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="item.quantity"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="item.priceCompraUnit"
                          />
                        </td>
                        <td class="text-right">
                          <b>{{ mtdGetSubTotal(index) }}</b>
                        </td>
                        <td>
                          <button type="button" class="btn btn-sm btn-danger" @click="mtdDelItemCarrito(index)">
                            <i class="fas fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4" class="text-right"><b>TOTAL:</b></td>
                        <td class="text-right">
                          <b>{{ cpGetTotal }}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CCol>
              <CCol lg="12" xl="12" md="12" sm="12" xs="12">
                <div class="d-flex justify-content-evenly">
                  <button
                    type="button"
                    :disabled="cpButtonVaciar"
                    class="btn btn-sm w-100 text-white btn-danger"
                    @click="mtdVaciar"
                  >
                    <i class="fas fa-trash"></i>&nbsp; Vaciar Detalle
                  </button>
                  <button
                    type="button"
                    :disabled="cpButtonSave"
                    class="btn btn-sm w-100 text-white ml-1 pl-1 btn-info"
                    @click="mtdSave"
                  >
                    <i class="fas fa-save"></i>&nbsp; Guardar Compra
                  </button>
                </div>
              </CCol>
            </CRow>
            <CRow v-else>
              <CCol lg="12" xl="12" md="12" sm="12" xs="12" class="text-center">
                <b>NO HAY DETALLE AGREGADO</b>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";

export default {
  name: "c-process-comprastienda",
  components: { cSelectForm },
  data() {
    return {
      dataProviders: [],
      dataProducts: [],
      purchase: {
        provider: 0,
        factura: "",
        detail: [],
      },
      activePrd: "",
      tabs: [],
      activeTab: 1,
      errorMessage: "",
    };
  },
  created() {
    this.mtdGetdata();
  },
  computed: {
    cpGetTotal() {
      let total = 0;
      this.purchase.detail.forEach((element) => {
        total += parseFloat(element.subtotal);
      });
      return parseFloat(total).toFixed(2);
    },
    cpButtonVaciar() {
      if (this.purchase.detail.length > 0) return false;
      return true;
    },
    cpButtonSave() {
      if (
        this.purchase.provider > 0 &&
        this.purchase.detail.length > 0 &&
        this.purchase.factura !== ""
      ){
        let boo = 0;
        this.purchase.detail.forEach(element => {
          if (element.subtotal == 0.00 ) {
            boo ++;
          }
        });
        if (boo == 0) {
          return false;
        } else {
          return true;
        }
      }else{
        return true;
      }
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtdGetdata: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/process/purchase/" +
          this.$store.getters.get_campu.id +
          "/2",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.dataProviders = response.data.providers;
          this.dataProducts = response.data.product;
          this.purchase = {
            provider: 0,
            factura: "",
            detail: [],
          };
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdSelectProduct: function (payload) {
      if (this.mtdGetActive(payload.label) != "success") {
        this.activePrd += "," + payload.label;
        let itemDetail = {
          product: payload.value,
          name: payload.label,
          priceCompraUnit: 0,
          quantity: 0,
          subtotal: 0.00,
        };
        this.purchase.detail.push(itemDetail);
      } else {
        this.mtdDelItem(payload);
      }
    },
    mtdGetActive: function (pos) {
      if (this.activePrd.split(",").includes(pos)) {
        return "success";
      } else {
        return "";
      }
    },
    mtdDelItem: function (payload) {
      let temp = [];
      this.purchase.detail.forEach((element, index) => {
        if (element.product != payload.value) {
          temp.push(element);
        }
      });
      this.purchase.detail = temp;
      let ar = this.activePrd.split(",");
      this.activePrd = "";
      for (let index = 1; index < ar.length; index++) {
        const element = ar[index].toString();
        // console.log(element != payload.label);
        if (element != payload.label) {
          this.activePrd += "," + element;
        }
      }
    },
    mtdGetSubTotal: function (index) {
      let tot = 0;
      tot = parseFloat(
        this.purchase.detail[index].quantity *
          this.purchase.detail[index].priceCompraUnit
      ).toFixed(2);
      this.purchase.detail[index].subtotal = tot;
      return tot;
    },
    mtdVaciar: function () {
      this.purchase.detail = [];
      this.activePrd= "";
    },
    mtdDelItemCarrito: function(pos){
      let temp=[];
      this.activePrd = "";      
      this.purchase.detail.forEach((element,index) => {
        if (pos != index) {
          this.activePrd += "," + element.name;
          temp.push(element);
        }
      });
      this.purchase.detail= temp;
    },
    mtdSave: function () {
      this.post({
        url: this.$store.getters.get__url + "/process/purchasetienda/store",
        token: this.$store.getters.get__token,
        params: {
          provider: this.purchase.provider,
          factura: this.purchase.factura,
          detail: this.purchase.detail,
          subtotal: 0, //this.cpSubTotal,
        },
      })
        .then((response) => {
          if (response.error == false) {
            /** si toto esta correcto limpia la data */
            this.purchase = {
              provider: 0,
              facutra: "",
              detail: [],
            };
            this.activePrd = "";
            this.tabs = [];
            this.activeTab = 1;
            this.itemDetail = {
              product: 0,
              priceCompraUnit: 0,
              quantityUnit: 0,
              quantityJava: 0,
              quantityKg: 0,
              subtotal: 0,
              pesoJava: 0,
              netoKg: 0,
            };
            bus.$emit("alert", {
              color: "success",
              message: "Compra realizada correctamente",
            });
          } else {
            bus.$emit("alert", {
              color: "warning",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>