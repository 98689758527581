<template>
  <div>
    <CRow class="mt-3">
      <CCol lg="4" xl="4" md="12" sm="12" xs="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span
              ><i class="fas fa-dolly"></i>&nbsp; FACTURAS PENDIENTE DE
              PAGOS</span
            >
            <button
              @click="mtdBack"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol
                xs="12"
                sm="12"
                md="12"
                lg="12"
                class="text-center"
                v-if="purchases.length === 0"
              >
                <h4>NO HAY PENDIENTES DE PAGO</h4>
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12">
                <ul class="list-group">
                  <li
                    v-for="(item, index) in purchases"
                    :key="index"
                    :class="mtdGetClasesPurchase(item.state, index)"
                    @click="mtdSelectPurchase(item, index)"
                    class="mt-2"
                  >
                    {{ item.factura }} {{ item.provider.name }}
                  </li>
                </ul>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol
        lg="8"
        xl="8"
        md="12"
        sm="12"
        xs="12"
        v-if="keyPurchaseSelected != null"
      >
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span
              ><i class="fas fa-piggy-bank"></i>&nbsp; PAGOS DE LA FACTURA:
              {{ selectPurchase.factura }}
              {{ selectPurchase.provider.name }}</span
            >
            <button
              @click="mtdAddPayment"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-plus"></i> Agregar pago
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="4" lg="4">
                <button
                  type="button"
                  class="btn btn-sm text-white btn-info w-100 mb-2"
                >
                  TOTAL: S/{{ selectPurchase.total }}
                </button>
              </CCol>
              <CCol xs="12" sm="12" md="4" lg="4">
                <button
                  type="button"
                  class="btn btn-sm text-white btn-success w-100 mb-2"
                >
                  PAGADO: S/{{
                    parseFloat(
                      selectPurchase.total - selectPurchase.pending
                    ).toFixed(2)
                  }}
                </button>
              </CCol>
              <CCol xs="12" sm="12" md="4" lg="4">
                <button
                  type="button"
                  class="btn btn-sm text-white btn-danger w-100 mb-2"
                >
                  PENDIENTE: S/{{ selectPurchase.pending }}
                </button>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="12" xl="12" md="12" sm="12" xs="12">
                <div class="position-relative table-responsive">
                  <table class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>FECHA</th>
                        <th>BANCO</th>
                        <th>MONTO</th>
                        <!-- <th>ACCIÓN</th> -->
                      </tr>
                    </thead>
                    <tbody v-if="selectPurchase.payment.length > 0">
                      <tr
                        v-for="(item, index) in selectPurchase.payment"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.date }}</td>
                        <td>{{ item.bank.name }}</td>
                        <td>{{ item.amount }}</td>
                        <!-- <td></td> -->
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="5" class="text-center">
                          <b>NO TIENE HISTORIAL DE PAGOS</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- modal agregar pago-->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="'Agregar pago'"
      :button="cp_button"
      :show.sync="modal.modal_form"
      :color="'info'"
      :centered="true"
      @mtd_action="mtdSavePayment"
    >
      <CRow>
        <CCol
          lg="12"
          xl="12"
          md="12"
          sm="12"
          xs="12"
          class="text-center text-bold"
        >
          <h5>Pendiente: S/{{ selectPurchase.pending }}</h5>
        </CCol>
        <CCol
          lg="12"
          xl="12"
          md="12"
          sm="12"
          xs="12"          
        >
          <CInput
            label="Monto a pagar"
            v-model="payment.amount"
            placeholder="0"
            @keyup="mtdVerifAmount"
            size="sm"
            v-on:keypress="isNumber($event)"
          />
        </CCol>
        <CCol
          lg="12"
          xl="12"
          md="12"
          sm="12"
          xs="12"          
        >
        <label for="">Banco</label>
          <select class="form-control form-control-sm" v-model="payment.bank">
            <option value="0">[Seleccione un banco..]</option>
            <option v-for="(item, index) in bank" :key="index" :value="item.id">{{ item.name }}</option>
          </select>
        </CCol>
      </CRow>
    </CModalForm>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";
import CModalForm from "../../components/shared/modals/cModalForm.vue";

export default {
  name: "c-proccess-purchasepayment",
  components: { CModalForm },
  data() {
    return {
      purchases: [],
      bank: [],
      keyPurchaseSelected: null,
      selectPurchase: [],
      modal: {
        modal_form: false,
      },
      payment: {
        purchase: null,
        amount: "",
        bank: 0,
      },
    };
  },
  computed: {
    cp_button: function () {
      if (this.payment.amount != "" && this.payment.bank>0) return false;
      return true;
    },
  },
  created() {
    this.mtdGetdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtdGetdata: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/process/payment/" +
          this.$store.getters.get_campu.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.purchases = response.data.purchase;
          this.bank = response.data.bank;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdGetClasesPurchase: function (state, pos) {
      let clases = "";
      if (pos == this.keyPurchaseSelected) {
        clases = "list-group-item list-group-item-action active";
      } else {
        switch (state) {
          case 1:
            clases = "list-group-item list-group-item-primary";
            break;
          default:
            break;
        }
      }
      return clases;
    },
    mtdSelectPurchase: function (payload, pos) {
      if (payload.total == 0.0) {
        bus.$emit("alert", {
          color: "warning",
          message: "Registre precio de compra!!",
        });
      } else {
        this.keyPurchaseSelected = pos;
        this.selectPurchase = payload;
      }
    },
    mtdAddPayment: function () {
      this.modal.modal_form = true;
      this.payment =  {
        purchase: this.selectPurchase.id,
        amount: "",
        bank: 0,
      };
    },
    mtdVerifAmount: function(){
      if ( parseFloat(this.payment.amount) > parseFloat(this.selectPurchase.pending)) {
        this.payment.amount="";
        bus.$emit("alert", {
          color: "warning",
          message: "No puede pasar el pendiente!!",
        });
      }
    },
    mtdSavePayment: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/process/payment/store/" +
          this.$store.getters.get_campu.id,
        token: this.$store.getters.get__token,
        params: this.payment
      })
        .then((response) => {
          if (!response.eror ) {
            this.modal= {
              modal_form: false,
            };
            this.payment= {
              purchase: null,
              amount: "",
              bank: 0,
            };
            this.mtdGetdata();
            this.keyPurchaseSelected= null;
            this.selectPurchase= [];
            bus.$emit("alert", {
              color: "success",
              message: response.message,
            });
          } else {
            bus.$emit("alert", {
              color: "error",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }, 
  },
};
</script>

<style lang="scss" scoped>
</style>