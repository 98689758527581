<template>
  <div>
    <div class="row" id="div_conte2">
      <div id="div_conte" class="col-md-10 mx-auto">
        <div class="bg-secondary text-white bg-opacity-10">
          <div class="text-center py-4 px-5" id="scroll-div">
            <div class="row">
              <!-- <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('purchase')"
              >
                COMPRAS CAMAL
              </button> -->
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('purchaseTienda')"
              >
                COMPRAS TIENDA
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('devolutionpurchase')"
              >
                DEVOLUCIONES DE COMPRAS
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('editPrices')"
              >
                EDITAR PRECIOS
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('purchasePayment')"
              >
                PAGOS FACTURAS
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('payment')"
              >
                PAGOS DE CLIENTES
              </button>
              <!-- <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('dispatch')"
              >
                DESPACHAR
              </button> -->
            </div>
            <div class="row">
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('credits')"
              >
                CREDITOS
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('proccess')"
              >
                PROCESAR DESPACHO
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('sale')"
              >
                VENTAS TIENDA
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('devolution')"
              >
                DEVOLUCIONES VENTAS
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('commitdevolution')"
              >
                AUTORIZAR DEVOLUCIONES
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "c-process-home",
  methods: {
    changePage: function (page) {
      this.$emit("changePage", page);
    },
  },
};
</script>

<style scoped>
#div_conte {
  border: 1.5px solid #926cdc;
  padding: 0;
  margin: 40px 0;
}
.div-button {
  background: #926cdc;
  border-radius: 15px;
  border: none;
}
#scroll-div {
  height: auto;
  overflow-y: scroll;
}

#scroll-div > div > button {
  height: 65px;
}
@media (max-width: 1320px) {
  #div_conte {
    border: 1.5px solid #926cdc;
    padding: 0;
    margin: 60px 0;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .row {
    display: block;
  }
  #div_conte {
    border: 1.5px solid #926cdc;
    padding: 0;
    margin: 100px 0;
  }
  #scroll-div {
    height: 518px;
  }
}
@media (max-width: 768px) {
  #div_conte2 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .row {
    display: block;
  }
  #scroll-div > div > button {
    height: 44px;
  }
  .container {
    font-size: 11px;
  }
  #scroll-div {
    height: 450px;
  }
}
@media (max-width: 415px) {
  #scroll-div {
    height: 450px;
  }
}
</style>
  