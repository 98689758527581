<template>
  <div>
    <CRow class="mt-1">
      <CCol lg="4" xl="4" md="12" sm="12" xs="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-dolly"></i>&nbsp; COMPRAR INSUMOS</span>
            <button
              @click="mtdBack"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="12">
                <cSelectForm
                  label="Seleccione un proveedor"
                  :options="dataProviders"
                  placeholder="Escoja un proveedor"
                  :value.sync="purchase.provider"
                />
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12">
                <CInput
                  label="N°. Factura"
                  placeholder="Digite n° de documento"
                  v-model="purchase.factura"
                />
              </CCol>
            </CRow>
            <h3>Detalle de pedido</h3>
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="12">
                <CListGroup>
                  <CListGroupItem
                    v-for="(item, index) in dataProducts"
                    :key="index"
                    :color="mtdGetActive(item.label)"
                    @click="mtdSelectProduct(item)"
                    >{{ item.label }}</CListGroupItem
                  >
                </CListGroup>
              </CCol>
              <!-- <CCol xs="12" sm="12" md="12" lg="12">
                <cSelectForm
                  label="Seleccione un producto"
                  :options="dataProducts"
                  placeholder="Escoja un producto"
                  :value.sync="itemDetail.product"
                />
              </CCol>
              <CCol xs="12" sm="12" md="4" lg="4">
                <CInput
                  label="Cant. Uni."
                  placeholder="0"
                  v-model="itemDetail.quantityUnit"
                  v-on:keypress="isNumber($event)"
                />
              </CCol>
              <CCol xs="12" sm="12" md="4" lg="4">
                <CInput
                  label="Cant. Java"
                  placeholder="0"
                  v-model="itemDetail.quantityJava"
                  v-on:keypress="isNumber($event)"
                />
              </CCol>
              <CCol xs="12" sm="12" md="4" lg="4">
                <CInput
                  label="Peso Java"
                  placeholder="0"
                  v-model="itemDetail.pesoJava"
                  v-on:keypress="isNumber($event)"
                />
              </CCol>
              <CCol xs="12" sm="12" md="4" lg="4">
                <CInput
                  label="Precio Kg"
                  placeholder="0"
                  v-model="itemDetail.priceCompraUnit"
                  v-on:keypress="isNumber($event)"
                />
              </CCol>
              <CCol xs="12" sm="12" md="4" lg="4">
                <CInput
                  label="Cant. Kg"
                  placeholder="0"
                  v-model="itemDetail.quantityKg"
                  v-on:keypress="isNumber($event)"
                />
              </CCol>
              <CCol xs="12" sm="12" md="4" lg="4">
                <CInput
                  label="Peso neto"
                  placeholder="0"
                  v-model="cpNeto"
                  :disabled="true"
                />
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12">
                <button
                  type="button"
                  :disabled="cpButtonAdd"
                  style="background: #926cdc"
                  :class="'btn btn-sm w-100 text-white'"
                  @click="mtdAddDetail"
                >
                  <i class="fas fa-plus"></i>&nbsp; Agregar
                </button>
              </CCol> -->
              <!-- <CCol xs="12" sm="12" md="12" lg="12">
                <div v-if="errorMessage" class="text-danger mt-2">
                  {{ errorMessage }}
                </div>
              </CCol> -->
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol lg="8" xl="8" md="12" sm="12" xs="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-bars"></i>&nbsp; DETALLE DE COMPRA</span>
          </CCardHeader>
          <CCardBody>
            <CRow v-if="purchase.detail.length > 0">
              <CCol xs="12" sm="12" md="12" lg="12">
                <CTabs add-tab-classes="mt-1">
                  <CTab v-for="(item, index) in purchase.detail" :key="index">
                    <template slot="title">
                      <i class="fas fa-drumstick-bite"></i> {{ item.name }}
                    </template>
                    <CDetailItemPurchaseVue :data="item" />
                  </CTab>
                </CTabs>
              </CCol>
              <!-- <CCol xs="12" sm="12" md="12" lg="12">
                <div class="position-relative table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Producto</th>
                        <th scope="col">Precio kg</th>
                        <th scope="col">Cant. Unidades</th>
                        <th scope="col">Cant. Javas</th>
                        <th scope="col">Peso por Javas</th>
                        <th scope="col">Cant. Kg</th>
                        <th scope="col">Peso total javas</th>
                        <th scope="col">Peso neto</th>
                        <th scope="col">Subtotal</th>
                        <th scope="col">Eliminar</th>
                      </tr>
                    </thead>
                    <tbody class="position-relative">
                      <tr v-if="purchase.detail.length == 0">
                        <td colspan="11" class="text-center">
                          No hay datos para mostrar
                        </td>
                      </tr>
                      <tr
                        v-else
                        v-for="(item, index) in purchase.detail"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.product[0].label }}</td>
                        <td>
                          S/{{ parseFloat(item.priceCompraUnit).toFixed(2) }}
                        </td>
                        <td>{{ parseFloat(item.quantityUnit).toFixed(2) }}</td>
                        <td>{{ parseFloat(item.quantityJava).toFixed(2) }}</td>
                        <td>{{ parseFloat(item.pesoJava).toFixed(2) }}</td>
                        <td>{{ parseFloat(item.quantityKg).toFixed(2) }}</td>
                        <td>
                          {{
                            parseFloat(
                              item.pesoJava * item.quantityJava
                            ).toFixed(2)
                          }}
                        </td>
                        <td>
                          {{ parseFloat(item.netoKg).toFixed(2) }}
                        </td>
                        <td class="text-right">
                          S/{{
                            parseFloat(
                              item.priceCompraUnit * item.netoKg
                            ).toFixed(2)
                          }}
                        </td>
                        <td class="text-center">
                          <button
                            type="button"
                            class="btn btn-sm btn-danger text-white"
                            @click="mtdDelItem(index)"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot v-if="purchase.detail.length > 0">
                      <tr>
                        <th class="text-right" colspan="9">TOTALES:</th>
                        <th class="text-right">S/{{ cpSubTotal }}</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </CCol> -->
              <CCol xs="12" sm="12" md="12" lg="12">
                <div class="d-flex justify-content-evenly">
                  <button
                    type="button"
                    :disabled="cpButtonVaciar"
                    class="btn btn-sm w-100 text-white btn-danger"
                    @click="mtdVaciar"
                  >
                    <i class="fas fa-trash"></i>&nbsp; Vaciar Detalle
                  </button>
                  <button
                    type="button"
                    :disabled="cpButtonSave"
                    class="btn btn-sm w-100 text-white ml-1 pl-1 btn-info"
                    @click="mtdSave"
                  >
                    <i class="fas fa-save"></i>&nbsp; Guardar Compra
                  </button>
                </div>
              </CCol>
            </CRow>
            <CRow v-else>
              <CCol sm="12" xs="12" md="12" lg="12" class="text-center">
                <h4><b>NO TIENE DETALLE SELECCIONADO</b></h4>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import CDetailItemPurchaseVue from "./components/CDetailItemPurchase.vue";

export default {
  name: "c-process-compras",
  components: { cSelectForm, CDetailItemPurchaseVue },
  data() {
    return {
      dataProviders: [],
      dataProducts: [],
      purchase: {
        provider: 0,
        factura: "",
        detail: [],
      },
      activePrd: "",
      tabs: [],
      activeTab: 1,
      errorMessage: "",
    };
  },
  created() {
    this.mtdGetdata();
  },
  computed: {
    cpNeto() {
      if (
        this.itemDetail.quantityJava > 0 &&
        this.itemDetail.pesoJava > 0 &&
        this.itemDetail.quantityKg > 0
      ) {
        let pesoJavaTo = parseFloat(
          this.itemDetail.quantityJava * this.itemDetail.pesoJava
        );
        this.itemDetail.netoKg = parseFloat(
          this.itemDetail.quantityKg - pesoJavaTo
        ).toFixed(2);
        return parseFloat(this.itemDetail.quantityKg - pesoJavaTo).toFixed(2);
      } else {
        return 0.0;
      }
    },
    cpButtonAdd() {
      if (
        this.itemDetail.product > 0 &&
        this.itemDetail.quantityUnit > 0 &&
        this.itemDetail.quantityJava > 0 &&
        this.itemDetail.quantityKg > 0
      )
        return false;
      return true;
    },
    cpSubTotal() {
      let tot = 0;
      this.purchase.detail.forEach((element) => {
        tot += parseFloat(element.priceCompraUnit * element.netoKg);
      });
      return parseFloat(tot).toFixed(2);
    },
    cpButtonVaciar() {
      if (this.purchase.detail.length > 0) return false;
      return true;
    },
    cpButtonSave() {
      if (
        this.purchase.provider > 0 &&
        this.purchase.detail.length > 0 &&
        this.purchase.factura !== ""
      )
        return false;
      return true;
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtdGetdata: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/process/purchase/" +
          this.$store.getters.get_campu.id+"/1",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.dataProviders = response.data.providers;
          this.dataProducts = response.data.product;
          this.purchase = {
            provider: 0,
            factura: "",
            detail: [],
          };
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdSelectProduct: function (payload) {
      if (this.mtdGetActive(payload.label) != "success") {
        this.activePrd += "," + payload.label;
        let itemDetail = {
          product: payload.value,
          name: payload.label,
          priceCompraUnit: 0,
          pesoJava: 0,
          detail: [],
        };
        this.purchase.detail.push(itemDetail);
      } else {
        this.mtdDelItem(payload);
      }
    },
    mtdGetActive: function (pos) {
      if (this.activePrd.split(",").includes(pos)) {
        return "success";
      } else {
        return "";
      }
    },
    mtdDelItem: function (payload) {
      let temp = [];
      this.purchase.detail.forEach((element, index) => {
        if (element.product != payload.value) {
          temp.push(element);
        }
      });
      this.purchase.detail = temp;
      let ar = this.activePrd.split(",");
      this.activePrd = "";
      for (let index = 1; index < ar.length; index++) {
        const element = ar[index].toString();
        // console.log(element != payload.label);
        if (element != payload.label) {
          this.activePrd += "," + element;
        }
      }
    },
    mtdVaciar: function () {
      this.purchase.detail = [];
      this.activePrd="";
    },
    mtdSave: function () {
      this.post({
        url: this.$store.getters.get__url + "/process/purchase/store",
        token: this.$store.getters.get__token,
        params: {
          provider: this.purchase.provider,
          factura: this.purchase.factura,
          detail: this.purchase.detail,
          subtotal: 0, //this.cpSubTotal,
        },
      })
        .then((response) => {
          if (response.error == false) {
            /** si toto esta correcto limpia la data */
            this.purchase = {
              provider: 0,
              facutra: "",
              detail: [],
            };
            this.activePrd = "";
            this.tabs = [];
            this.activeTab = 1;
            this.itemDetail = {
              product: 0,
              priceCompraUnit: 0,
              quantityUnit: 0,
              quantityJava: 0,
              quantityKg: 0,
              subtotal: 0,
              pesoJava: 0,
              netoKg: 0,
            };
            bus.$emit("alert", {
              color: "success",
              message: "Compra realizada correctamente",
            });
          } else {
            bus.$emit("alert", {
              color: "warning",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    /** =============  */
    mtdAddDetail: function () {
      /**Verificar si el producto se encuentra seleccionado */
      const productExists = this.purchase.detail.some(
        (item) => item.product[0].value === this.itemDetail.product
      );
      if (productExists) {
        this.errorMessage =
          "El producto ya está agregado en la lista de detalles.";
        return;
      }

      const prd = this.dataProducts.filter(
        (prd) => prd.value == this.itemDetail.product
      );
      this.itemDetail.product = prd;

      // Cálculo del subtotal
      this.itemDetail.subtotal = parseFloat(
        this.itemDetail.priceCompraUnit
      ).toFixed(2);

      this.purchase.detail.push(this.itemDetail);
      this.itemDetail = {
        product: 0,
        priceCompraUnit: 0,
        quantityUnit: 0,
        quantityJava: 0,
        quantityKg: 0,
        subtotal: 0,
        pesoJava: 0,
        netoKg: 0,
      };
      this.errorMessage = "";
    },

    /** utils */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>