<template>
  <div>
    <HomeProcess v-if="view == 'home'" @changePage="changePage" />
    <SaleVue v-if="view =='sale'" @changePage="changePage" @mtdBack="mtdBack"/>
    <PagoVue v-if="view =='payment'" @changePage="changePage" @mtdBack="mtdBack"/>
    <CreditVue v-if="view =='credits'" @changePage="changePage" @mtdBack="mtdBack"/>
    <DevolucionVue v-if="view =='devolution'" @changePage="changePage" @mtdBack="mtdBack"/>
  </div>
</template>
    
    <script>
import HomeProcess from "../dealer/HomeProcess.vue";
import SaleVue from '../Process/Sale.vue';
import PagoVue from '../Process/Pagos.vue';
import CreditVue from '../Process/Credito.vue';
import DevolucionVue from '../Process/Devolucion.vue';

export default {
  name: "c-process",
  data() {
    return {
      view: "home",
    };
  },
  components: {
    HomeProcess,
    SaleVue,
    PagoVue,
    CreditVue,
    DevolucionVue
  },
  methods: {
    changePage: function (view) {
      this.view = view;
      this.$emit("mtdViewFooter", false, "process");
    },
    mtdBack: function () {
      this.view = "home";
      this.$emit("mtdViewFooter", true, "process");
    },
  },
};
</script>
    
    
