<template>
  <div>
    <homeProcess v-if="view == 'home'" @changePage="changePage" />
    <ComprasVue v-if="view == 'purchase'" @changePage="changePage" @mtdBack="mtdBack" />
    <DespachosVue v-if="view == 'dispatch'" @changePage="changePage" @mtdBack="mtdBack"/>
    <SaleVue v-if="view =='sale'" @changePage="changePage" @mtdBack="mtdBack" :warehouse="2"/>
    <PagosVue v-if="view =='payment'" @changePage="changePage" @mtdBack="mtdBack"/>
    <CreditVue v-if="view =='credits'" @changePage="changePage" @mtdBack="mtdBack" />
    <EditarPreciosVue v-if="view =='editPrices'" @changePage="changePage" @mtdBack="mtdBack" />
    <DevolucionVue v-if="view =='devolution'" @changePage="changePage" @mtdBack="mtdBack" />
    <CommitDevolucionVue v-if="view =='commitdevolution'" @changePage="changePage" @mtdBack="mtdBack" />
    <PurchasePaymentVue v-if="view =='purchasePayment'" @changePage="changePage" @mtdBack="mtdBack" />
    <PurchaseTiendaVue v-if="view =='purchaseTienda'" @changePage="changePage" @mtdBack="mtdBack" />
    <DevolutionPurchaseVue v-if="view =='devolutionpurchase'" @changePage="changePage" @mtdBack="mtdBack"/>
    <ProccesDespachoTiendaVue v-if="view =='proccess'" @changePage="changePage" @mtdBack="mtdBack"/>
  </div>
</template>
  
  <script>
import ComprasVue from "./Compras.vue";
import DespachosVue from './Despachos.vue';
import homeProcess from "./Home.vue";
import PagosVue from './Pagos.vue';
import SaleVue from './SaleStock.vue';
import CreditVue from './Credito.vue';
import EditarPreciosVue from './EditarPrecios.vue';
import DevolucionVue from './Devolucion.vue';
import CommitDevolucionVue from './CommitDevolucion.vue';
import PurchasePaymentVue from './PurchasePayment.vue';
import PurchaseTiendaVue from './PurchaseTienda.vue';
import DevolutionPurchaseVue from './DevolutionPurchase.vue';
import ProccesDespachoTiendaVue from './ProccesDespachoTienda.vue';

export default {
  name: "c-process",
  data() {
    return {
      view: "home",
    };
  },
  components: {
    homeProcess,
    ComprasVue,
    DespachosVue,
    SaleVue,
    PagosVue,
    CreditVue,
    EditarPreciosVue,
    DevolucionVue,
    CommitDevolucionVue,
    PurchasePaymentVue,
    PurchaseTiendaVue,
    DevolutionPurchaseVue,
    ProccesDespachoTiendaVue
  },
  methods: {
    changePage: function(view){
        this.view= view;
        this.$emit("mtdViewFooter", false, "process");
    },
    mtdBack: function(){
      this.view= 'home';
        this.$emit("mtdViewFooter", true, "process");
    }
  },
};
</script>
  
  