<template>
  <div>
    <CRow>
      <CCol sm="12" xs="12" md="3" lg="3" xl="3"></CCol>
      <!--<CCol sm="12" xs="12" md="2" lg="2" xl="2" class="mt-2">
        <CInput
          label="Precio de compra"
          placeholder="0"
          v-model="data.priceCompraUnit"
          v-on:keypress="isNumber($event)"
        />
      </CCol>-->
      <CCol sm="12" xs="12" md="2" lg="2" xl="2" class="mt-2">
        <CInput
          label="Peso de jabas"
          placeholder="0"
          v-model="data.pesoJava"
          v-on:keypress="isNumber($event)"
        />
      </CCol>
      <CCol sm="12" xs="12" md="2" lg="2" xl="2" class="mt-2">
        <label>&nbsp;</label>
        <CButton class="" color="info" @click="mtdAddItem"
          ><i class="fas fa-plus"></i> Agregar Item</CButton
        >
      </CCol>
      <CCol sm="12" xs="12" md="12" lg="12" xl="12" class="mt-2">
        <div class="position-relative table-responsive">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Cant. Jaba</th>
                <th scope="col">Cant. Unidades</th>
                <th scope="col">Peso</th>
                <th scope="col">Genero (Macho/Hembra)</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody v-if="this.data.detail.length > 0">
              <tr v-for="(item, index) in this.data.detail" :key="index">
                <td width="1%">{{ index + 1 }}</td>
                <td width="30%">
                  <span v-if="lengthDetail != index">{{
                    item.quantityJaba
                  }}</span>
                  <input
                    v-else
                    type="text"
                    class="form-control form-control-sm"
                    v-model="item.quantityJaba"
                  />
                </td>
                <td width="30%">
                  <span v-if="lengthDetail != index">{{
                    item.quantityUnit
                  }}</span>
                  <input
                    v-else
                    type="text"
                    class="form-control form-control-sm"
                    v-model="item.quantityUnit"
                  />
                </td>
                <td width="30%">
                  <span v-if="lengthDetail != index">{{ item.peso }}Kg</span>
                  <input
                    v-else
                    type="text"
                    class="form-control form-control-sm"
                    v-model="item.peso"
                  />
                </td>
                <td  width="30%">
                  <span v-if="lengthDetail != index">{{ item.tipo }}</span>
                  <div v-else> 
                    <CInputCheckbox
                      :label="'Macho'"
                      :value="item.tipo == 'Macho'"
                      @input="item.tipo = $event ? 'Macho' : null"
                    />
                    <CInputCheckbox
                      :label="'Hembra'"
                      :value="item.tipo == 'Hembra'"
                      @input="item.tipo = $event ? 'Hembra' : null"
                    />
                  </div>
                </td>
                <td width="20%" class="text-center">
                  <div class="d-flex justify-content-evenly">
                    <template v-if="lengthDetail == index">
                      <button
                        class="btn btn-sm btn-danger"
                        @click="mtdCancelAddItem"
                      >
                        <i class="fas fa-times-circle"></i>
                      </button>
                      <button
                        class="btn btn-sm btn-success ml-1"
                        @click="mtdCommitAddItem"
                      >
                        <i class="fas fa-save"></i>
                      </button>
                    </template>
                    <template v-else>
                      <button
                        class="btn btn-sm btn-danger"
                        @click="mtdDeleteItem(index)"
                        :disabled="addItem"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </template>
                  </div>
                </td>
              </tr>
              <tr v-if="!addItem">
                <th scope="col">TOTAL</th>
                <th scope="col">{{ cpTotalCantidadJaba }}</th>
                <th scope="col">{{ cpTotalCantidadUnit }}</th>
                <th scope="col">{{ cpTotalCantidadKg+"Kg" }}</th>
                <!-- <th scope="col">GENERO</th>                 -->
              </tr>
              <tr v-if="!addItem">
                <th scope="col" class="text-center" colspan="2">PESO JABA: {{ cpTotalPesoJaba }} Kg</th>
                <th scope="col" class="text-center" colspan="2">PESO NETO: {{ cpTotalPesoNeto }} Kg</th>
                <!-- <th scope="col" class="text-center" colspan="1">S/{{ cpTotalPrecio }}</th> -->
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center" colspan="6"><b>NO HAY DETALLE</b></td>
              </tr>
            </tbody>
          </table>
        </div>
      </CCol>
    </CRow>
    <!-- {{ data }} -->
  </div>
</template>

<script>
import { bus } from "../../../main";
export default {
  name: "c-proccess-detailitempurchase",
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      addItem: false,
      detItem: {
        quantityJaba: 0,
        quantityUnit: 0,
        peso: 0,
        tipo: "",
      },
      lengthDetail: 0,
      cantJaba:0,
      pesoTotal:0,
      pesoNeto:0
    };
  },
  computed: {
    cpTotalCantidadJaba(){
        let tot = 0;
        this.data.detail.forEach(element => {
            tot += parseFloat(element.quantityJaba);
        });
        this.cantJaba=tot;
        return parseFloat(tot).toFixed(2);
    },
    cpTotalCantidadUnit(){
        let tot = 0;
        this.data.detail.forEach(element => {
            tot += parseFloat(element.quantityUnit);
        });
        return parseFloat(tot).toFixed(2);
    },
    cpTotalCantidadKg(){
        let tot = 0;
        this.data.detail.forEach(element => {
            tot += parseFloat(element.peso);
        });
        this.pesoTotal=parseFloat(tot);
        return parseFloat(tot).toFixed(2);
    },
    cpTotalPesoJaba(){
        return parseFloat(this.cantJaba * this.data.pesoJava).toFixed(2);
    },
    cpTotalPesoNeto(){
        this.pesoNeto =parseFloat(this.pesoTotal - (this.cantJaba * this.data.pesoJava)).toFixed(2);
        return parseFloat(this.pesoTotal - (this.cantJaba * this.data.pesoJava)).toFixed(2);
    },
    cpTotalPrecio(){
      return parseFloat(this.pesoNeto * this.data.priceCompraUnit).toFixed(2);
    }
  },
  methods: {
    /** utils */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    mtdAddItem: function () {
      if (!this.addItem) {
        this.lengthDetail = this.data.detail.length;
        this.data.detail.push(this.detItem);
        this.addItem = true;
      }
    },
    mtdCancelAddItem: function () {
      this.data.detail.pop();
      this.addItem = false;
    },
    mtdCommitAddItem: function () {
      if (
        // this.data.detail[this.lengthDetail].quantityJaba > 0 &&
        this.data.detail[this.lengthDetail].quantityUnit > 0 &&
        this.data.detail[this.lengthDetail].peso > 0 &&
        this.data.detail[this.lengthDetail].tipo != null
      ) {
        this.lengthDetail = this.data.detail.length;
        this.detItem = {
          quantityJaba: 0,
          quantityUnit: 0,
          peso: 0,
          tipo: "",
        };
        this.addItem = false;
      }else{
        bus.$emit("alert", {
            color: "warning",
            message: "Debe llenar todos los campos!!",
          });
      }
    },
    mtdDeleteItem: function (pos) {
      this.data.detail.splice(pos, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>