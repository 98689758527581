<template>
  <div>
    <CRow>
      <CCol xs="12" sm="12" md="4" lg="4" xl="4">
        <CCard>
          <CCardHeader>
            <i class="fa fa-filter"></i>&nbsp; Filtrar por
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="12">
                <cSelectForm
                  label="Seleccione una Sede"
                  :options="dataCampus"
                  placeholder="Escoja una Sede"
                  :value.sync="filter.campu"
                />
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12">
                <button
                  type="button"
                  style="background: #926cdc"
                  :class="btnClasses"
                  @click="loadTable"
                  :disabled="filter.campu != null ? false : true"
                >
                  <i class="fas fa-search"></i>&nbsp; Buscar
                </button>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol v-if="vue.exp == true" xs="12" sm="12" md="8" lg="8" xl="8">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de cajas"
          icon="fas fa-cash-register"
          btn_name="Caja"
          @mtdBack="mtdBack"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'3%'"
          :buttonEdit="true"
          :buttonDelete="true"
        />
      </CCol>
    </CRow>

    <!-- modal form -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
      :color="'info'"
      :centered="true"
    >
      <CRow>
        <CCol xs="12" sm="12" md="12" lg="12" xl="12">
          <CInput
            label="Nombre"
            placeholder="Digite nombre"
            v-model="box.name"
          />
        </CCol>
        <CCol v-if="modal.action != 'store'" xs="12" sm="12" md="12" lg="12" xl="12">
          <label for="">Estado</label>
          <CSwitchVue
            :key="componentKey"
            @changeSwitch="changeSwitch"
            class="mx-1 pl-2 pt-2"
            :checked="box.state == 1 ? true : false"
            color="success"
            variant="opposite"
            shape="pill"
            v-bind="labelIcon"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="'Borrar Rol'"
      :show.sync="modal_delete.boo"
      @mtd_commit="mtd_commit"
      :centered="true"
      :item="modal_delete.item"
    />
  </div>
</template>

<script>
const fields = [
  { key: "Id", _style: "width:1%" },
  { key: "name", label: "Nombre", _style: "width:15%;" },
  { key:'status',label:"Estado",_style: "width:5%;"}  
];
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import CSwitchVue from '../shared/switch/CSwitch.vue';
import { mapActions } from "vuex";
import { bus } from "../../main";
export default {
  name: "c-maintenance-box",
  components: { CTableWrapper, CModalForm, cModalDelete, cSelectForm, CSwitchVue },
  data() {
    return {
      prefix: "box",
      fields,
      vue: {
        exp: false,
        // texto: "",
        // estado: 1,
      },
      dataCampus: [],
      filter: {
        campu: null,
      },
      data: [],
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      box: {
        id: "",
        campu_id: null,
        name: "",
        state: "",
      },
      componentKey:0,
      labelIcon: {
        labelOn: '\u2713',
        labelOff: '\u2715'
      },
      modal_delete: {
        boo: false,
        item: [],
      },
    };
  },
  computed: {
    btnClasses() {
      return [`btn text-white w-100`];
    },
    cp_button: function () {
      if (this.box.name.length>0) return false;
      return true;
    },
  },
  created() {
    this.mtdInit();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtdInit: function () {
      const campus = this.$store.getters.get_campus;
      campus.forEach((element) => {
        this.dataCampus.push({
          value: element.id,
          label: element.name,
        });
      });
    },
    loadTable: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.filter.campu,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.vue.exp = true;
          this.data = response.data;
        })
        .catch((errors) => {});
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nueva Caja")
        : (this.modal.title = "Editar Caja");
      action == "store"
        ? (this.box = {
            id: "",
            campu_id: this.filter.campu,
            name: "",
            state: "",
          })
        : (this.box = this.box);
    },
    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.box,
      })
        .then((response) => {
          let color = "success";
          let message = "";
          if (this.modal.action == "store") {
            this.data.push(response.data[0]);
            message = "REGISTRADO CORRECTAMENTE";
            // this.mtd_getdata();
          } else {
            this.data.forEach((element) => {
              if (element.Id == response.data[0].Id) {
                element.name = response.data[0].name;
                element.status = response.data[0].status;
              }
              message = "EDITADO CORRECTAMENTE";
            });
          }
          this.modal = {
            action: "",
            title: "",
            modal_form: false,
            name: "",
          };
          this.dataStore = response.all;
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    changeSwitch: function(payload){
      this.box.state = payload == true?1:0;
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
          token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.box=response;
          this.mtd_open_modal(true, "update");
          // console.log(this.campu);
        })
        .catch((errors) => {});
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.Id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.Id != response.id) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete = {
            boo: false,
            item: [],
          };
          this.dataStore = response.all;
          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
