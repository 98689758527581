<template>
  <div>
    <CRow class="mt-3"> 
      <CCol sm="6" xs="12" md="4" lg="4" xl="2">
        <CWidgetProgressIcon
          :header="'S/'+cpTotCompras"
          text="COMPRAS"
          color="gradient-info"
          inverse
        >
          <button
            style="background: transparent; border: none; padding: 0;"
           @click="loadTablePurchase"> 
            <CIcon name="cil-cart" height="36" style="color:#ced2d8" />
          </button>
        </CWidgetProgressIcon>
      </CCol>
      <CCol sm="6" xs="12" md="4" lg="4" xl="2">
        <CWidgetProgressIcon
          :header="'S/'+cpTotalVentas"
          text="VENTAS"
          color="gradient-success"
          inverse
        >
          <button
            style="background: transparent; border: none; padding: 0;"
           @click="loadTableSales"> 
            <CIcon name="cil-dollar" height="36" style="color:#ced2d8" />
          </button>
        </CWidgetProgressIcon>
      </CCol>
      <CCol sm="6" xs="12" md="4" lg="4" xl="2">
        <CWidgetProgressIcon
          :header="'S/'+cpGanancias"
          text="GANANCIAS"
          color="gradient-warning"
          inverse
        >
          <CIcon name="cil-plus" height="36" />
        </CWidgetProgressIcon>
      </CCol>
      <CCol sm="6" xs="12" md="4" lg="4" xl="2">
        <CWidgetProgressIcon
          :header="cpKgDispatch+' Kg'"
          text="DESPACHADOS"
          color="gradient-primary"
          inverse
        >
          <CIcon name="cil-chartPie" height="36" />
        </CWidgetProgressIcon>
      </CCol>
      <CCol sm="6" xs="12" md="4" lg="4" xl="2">
        <CWidgetProgressIcon
          :header="cpKgSale+' Kg'"
          text="VENDIDOS"
          color="gradient-info"
          inverse
        >
          <CIcon name="cil-speedometer" height="36" />
        </CWidgetProgressIcon>
      </CCol>
      <CCol sm="6" xs="12" md="4" lg="4" xl="2">
        <CWidgetProgressIcon
          :header="cpKgMerma+' Kg'"
          text="MERMA"
          color="gradient-danger"
          inverse
        >
          <button
            style="background: transparent; border: none; padding: 0;"
           @click="loadTableMerma"> 
              <CIcon name="cil-speech" height="36" style="color:#ced2d8" />
          </button>
        </CWidgetProgressIcon>
      </CCol>
    </CRow>
    <CRow>
      <CCol v-if="vue.exp" lg="12" xl="12" md="12" sm="12" xs="12">
        <CCard>
          <CCardBody>
            <!--Tabla Compras-->
            <table v-if="vue.showPurchase" class="table table-striped table-bordered" >
              <thead style="background: linear-gradient(45deg, #39f 0%, #2982cc 100%) !important;">
                <tr>
                  <th scope="col" style="color: #fff;" >N°</th>
                  <th scope="col" style="color: #fff;" >Documento</th>
                  <th scope="col" style="color: #fff;" >Fecha</th>
                  <th scope="col" style="color: #fff;" >Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in purchase" :key="index">
                  <td>{{ index+1 }}</td>
                  <td>{{ item.factura }}</td>
                  <td>{{ item.date }}</td>
                  <td>{{ item.total }}</td>
                </tr>
                <tr v-if="purchase.length === 0">
                  <td colspan="3">No se han realizado compras el dia de hoy.</td>
                </tr>
              </tbody>
            </table>
            <!--Tabla Ventas-->
            <table v-else-if="vue.showSales" class="table table-striped table-bordered">
              <thead style="background: linear-gradient(45deg, #2eb85c 0%, #1b9e3e 100%) !important;">
                <tr>
                  <th scope="col" style="color: #fff;" >N°</th>
                  <th scope="col" style="color: #fff;" >N° Operación</th>
                  <th scope="col" style="color: #fff;" >Met. Pago</th>
                  <th scope="col" style="color: #fff;" >Fecha</th>
                  <th scope="col" style="color: #fff;" >Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in sale" :key="index">
                  <td>{{ index+1 }}</td>
                  <td>{{ item.num_ope == null ? "-" : item.num_ope }}</td>
                  <td>{{ item.method_payment.name }}</td>
                  <td>{{ item.date }}</td>
                  <td>{{ item.total }}</td>
                </tr>
                <tr v-if="sale.length === 0" >
                  <td colspan="5">No se han realizado ventas el dia de hoy.</td>
                </tr>
              </tbody>
            </table>
            <!--Tabla Mermas-->
            <table  v-else-if="vue.showMerma" class="table table-striped table-bordered">
              <thead style="background: linear-gradient(45deg, #e55353 0%, #d93737 100%) !important;">
                <tr>
                  <th scope="col" style="color: #fff;" >N°</th>
                  <th scope="col" style="color: #fff;" >Compra (Kg.)</th>
                  <th scope="col" style="color: #fff;" >Despacho (Kg.)</th>
                  <th scope="col" style="color: #fff;" >Venta (Kg.)</th>
                  <th scope="col" style="color: #fff;" >Merma 01 (Compra - Despacho)</th>
                  <th scope="col" style="color: #fff;" >Merma 02 (Despacho - Venta)</th>
                  <th scope="col" style="color: #fff;" >Merma Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ 1 }}</td>
                  <td>{{ cpKgPurchase }}</td>
                  <td>{{ cpKgDispatch }}</td>
                  <td>{{ cpKgSale }}</td>
                  <td>{{ cpKgMerma01 }}</td>
                  <td>{{ cpKgMerma }}</td>
                  <td>{{ cpKgMermaTotal }}</td>
                </tr>
              </tbody>
            </table>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  name: "c-caja-index-day",
  data() {
    return {
      vue: {
        exp: false,
        showPurchase: false,
        showSales: false,
        showMerma: false,
        texto: "",
        estado: 1,
      },
      purchase: [],
      charge:[],
      sale:[],
      dispatch:[],
      mermas:[],
    };
  },
  computed: {
    cpTotCompras(){
        let to = 0;
        this.purchase.forEach(element => {
            to += parseFloat(element.total);
        });
        return parseFloat(to).toFixed(2);
    },
    cpTotalVentas(){
        let to = 0;
        this.charge.forEach(element => {
            to += parseFloat(element.amount);
        });
        return parseFloat(to).toFixed(2);
    },
    cpGanancias(){
        let ganancia = parseFloat(this.cpTotalVentas) - parseFloat(this.cpTotCompras);
        return parseFloat(ganancia).toFixed(2);
    },
    cpKgPurchase(){
      let to = 0;
      this.purchase.forEach( element => {
        element.purchasedetail.forEach(ele => {
          to +=  parseFloat(ele.quantity_kg);
        });
      });
      return parseFloat(to).toFixed(2);
    },
    cpKgDispatch(){
        let to = 0;
        this.dispatch.forEach(element => {
            element.detail.forEach(ele => {
                to += parseFloat(ele.total_neto);
            });
        });
        return parseFloat(to).toFixed(2);
    },
    cpKgSale(){
        let to = 0;
        this.sale.forEach(element => {
            element.detail.forEach(ele => {
                to += parseFloat(ele.quantity);
            });
        });
        return parseFloat(to).toFixed(2);
    },
    cpKgMerma(){
        let merma = parseFloat(this.cpKgDispatch) - parseFloat(this.cpKgSale);
        return parseFloat(merma).toFixed(2);
    },
    cpKgMerma01(){
        let merma01 = parseFloat(this.cpKgPurchase) - parseFloat(this.cpKgDispatch);
        return parseFloat(merma01).toFixed(2);
    },
    cpKgMermaTotal(){
      let mermaTotal = parseFloat(this.cpKgMerma01) + parseFloat(this.cpKgMerma);
      return parseFloat(mermaTotal).toFixed(2);
    },
  },
  created() {
    this.mtdGetData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdGetData: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bead/index/day/" +
          this.$store.getters.get_campu.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.purchase=response.data.purchase;
          this.charge=response.data.charge;
          this.sale=response.data.sale;
          this.dispatch=response.data.dispatch;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    loadTablePurchase: function (){
      this.vue.exp = true;
      this.vue.showPurchase = true;
      this.vue.showSales = false;
      this.vue.showMerma = false;
    },
    loadTableSales: function (){
      this.vue.exp = true;
      this.vue.showPurchase = false;
      this.vue.showSales = true;
      this.vue.showMerma = false;
    },
    loadTableMerma: function (){
      this.vue.exp = true;
      this.vue.showPurchase = false;
      this.vue.showSales = false;
      this.vue.showMerma = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>