<template>
  <div class="container">
    <br><br>
    <CRow class="text-right">
      <CCol md="12" lg="12" sm="12" xs="12" >
        <button @click="mtdBackCampu" type="button" class="btn btn-sm text-white" style="background: #926cdc"><i class="fas fa-hand-point-left"></i> Cambiar de sede</button>
      </CCol>
    </CRow>
    <br>
    <CRow class="mt-3">
      <CCol xxl="12" xl="12" md="12" sm="12" class="text-center">
        <h4>ESCOJA EL ROL QUE QUIERE EJERCER</h4></CCol
      >
    </CRow>
    <CRow class="mt-3">
      <CCol xxl="3" xl="3" md="3" sm="12"></CCol>
      <CCol xxl="6" xl="6" md="6" sm="12" class="text-center">
        <div class="d-flex justify-content-around">
          <CCard
            v-for="(item, index) in roles"
            :key="index"
            class="mr-1 card"
            @click="mtdSetRol(item.role)"
            color="gradient-info"
            body-wrapper
            text-color="white"
          >
            {{ item.role.name }}
          </CCard>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "c-home-campus",
  props: {
    roles: {
      type: Array,
      default: [],
    },
  },
  created(){
    if(this.roles.length ==1){
      this.$emit("mtdSetRol", this.roles[0].role);
    }
  },
  methods: {
    mtdSetRol: function (payload) {
      this.$emit("mtdSetRol", payload);
    },
    mtdBackCampu: function(){
      this.$store.commit("mt_set_campu", null);
    }
  },
};
</script>

<style scoped>
.card {
  cursor: pointer;
}
</style>
