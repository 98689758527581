<template>
    <div>
        <CRow class="mt-3">
            <CCol lg="12" xl="12" md="12" sm="12" xs="12">
                <CCard>
                    <CCardHeader class="d-flex justify-content-between">
                        <span><i class="fas fa-dolly"></i>&nbsp; REPORTE DE STOCK</span>
                        <button
                            @click="mtdBack"
                            type="button"
                            class="btn btn-sm text-white"
                            style="background: #926cdc"
                        >
                            <i class="fas fa-hand-point-left"></i> Retroceder
                        </button>
                    </CCardHeader>
                    <CCardBody>
                        <CTabs add-tab-classes="mt-1" :justified="true">
                            <CTab active>
                                <template slot="title">
                                    <i class="fas fa-drumstick-bite"></i> CAMAL
                                </template>
                                <CRow class="mt-1">
                                    <CCol lg="12" xl="12" md="12" sm="12" xs="12">
                                        <CCard>
                                            <CCardBody>
                                                <CTabs add-tab-classes="mt-1" :justified="true" :vertical="true">
                                                    <CTab  v-for="(product, index) in tienda" :key="index" :active="index === 0">
                                                        <template slot="title">
                                                            <i class="fas fa-drumstick-bite"></i> {{product.product}}
                                                        </template>
                                                        <CRow class="d-flex justify-content">
                                                            <CCol xs="12" sm="12" md="4" lg="4" >
                                                                <button
                                                                type="button"
                                                                class="btn btn-xlm text-white btn-success w-100 "
                                                                >VIVO: {{ product.types[0].amount }} kg</button>
                                                            </CCol>
                                                            <CCol xs="12" sm="12" md="4" lg="4" >
                                                                <button
                                                                type="button"
                                                                class="btn btn-xlm text-white btn-info w-100 "
                                                                >BENEFICIADO: {{ product.types[1].amount }} kg</button>
                                                            </CCol>
                                                        </CRow>
                                                    </CTab>
                                                </CTabs>
                                            </CCardBody>
                                        </CCard>
                                    </CCol>
                                </CRow>
                            </CTab>
                            <CTab>
                                <template slot="title">
                                    <i class="fas fa-store"></i> TIENDA
                                </template>
                                <CRow class="mt-1">
                                    <CCol lg="12" xl="12" md="12" sm="12" xs="12">
                                        <CCard>
                                            <CCardBody>
                                                <CTabs add-tab-classes="mt-1" :justified="true" :vertical="true">
                                                    <CTab  v-for="(product, index) in camal" :key="index" :active="index === 0">
                                                        <template slot="title">
                                                            <i class="fas fa-drumstick-bite"></i> {{product.product}}
                                                        </template>
                                                        <CRow class="d-flex justify-content">
                                                            <CCol xs="12" sm="12" md="4" lg="4" >
                                                                <button
                                                                type="button"
                                                                class="btn btn-xlm text-white btn-success w-100 "
                                                                >VIVO: {{ product.types[0].amount }} kg</button>
                                                            </CCol>
                                                            <CCol xs="12" sm="12" md="4" lg="4" >
                                                                <button
                                                                type="button"
                                                                class="btn btn-xlm text-white btn-info w-100 "
                                                                >BENEFICIADO: {{ product.types[1].amount }}  kg</button>
                                                            </CCol>
                                                        </CRow>
                                                    </CTab>
                                                </CTabs>
                                            </CCardBody>
                                        </CCard>
                                    </CCol>
                                </CRow>
                            </CTab>
                        </CTabs>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
 import { mapActions } from "vuex";
 import { bus } from "../../main";

 export default {
    data() {
        return {
            prefix: "process",
            vue: {
                exp: false,
                texto: "",
                estado: 1,
            },
            tienda: [],
            camal: [],
        }
    },
    created() {
        this. mtd_getdata();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdBack: function () {
            this.$emit("mtdBack");
        },
        form_selected: function () {
            this.vue.estado = 0;
        },
        mtd_getdata: function () {
            this.get({
                url:
                this.$store.getters.get__url + "/process/stock" ,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.tienda = response.data.stockTienda;
                this.camal = response.data.stockCamal;
                // console.log(this.tienda);
                // console.log(this.camal);
            })
            .catch((errors) => {
                // this.errorsBackend = errors;
                // this.$emit("error", this.event);
            });
        },
    },
 }
</script>