<template>
  <div>
    <CRow>
      <CCol xs="12" sm="12" md="4" lg="4" xl="4">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-dolly"></i>&nbsp; COMPRAS</span>
            <button
              @click="mtdBack"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol
                xs="12"
                sm="12"
                md="12"
                lg="12"
                class="text-center"
                v-if="purchase.length === 0"
              >
                <h4>NO HAY COMPRAS REALIZADAS</h4>
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12">
                <ul class="list-group">
                  <li
                    v-for="(item, index) in purchase"
                    :key="index"
                    :class="mtdGetClasesDispatch(item.state, index)"
                    @click="mtdSelectDispatch(item, index)"
                  >
                    {{ item.documento }}
                  </li>
                </ul>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol xs="12" sm="12" md="8" lg="8" xl="8" v-if="selectPurchase">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-dolly"></i>&nbsp; Editar precios para el día: {{ hoy }}</span>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="12">
                <CTabs variant="pills" vertical>
                  <CTab
                    v-for="(item, index) in selectPurchase.purchasedetail"
                    :key="index"
                    class="w-100"
                  >
                    <template slot="title">
                      <span @click="mtdSelectProduct(index)">
                        <i class="fas fa-drumstick-bite"></i>
                        {{ item.product.name }}
                      </span>
                    </template>
                    <div xs="12" sm="12" md="12" lg="12" v-if="item.gasto.length > 0">
                      <div class="position-relative table-responsive">
                        <table class="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Gasto</th>
                              <th>Monto</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(it, ind) in item.gasto" :key="ind">
                              <td>{{ ind + 1 }}</td>
                              <td>{{ it.bill.name }}</td>
                              <td>
                                <input v-on:keypress="isNumber($event)" type="text" :disabled="item.save" class="form-control form-control-sm" v-model="it.amount" placeholder="Precio de compra">
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2"><b>TOTAL INVERSIÓN </b></td>
                              <td><b>S/ {{ cpGetTotalInversion }}</b></td>
                            </tr>
                            <tr>
                              <td colspan="2"><b>PESO NETO </b></td>
                              <td><b>{{ cpGetPesoNeto }} Kg</b></td>
                            </tr>
                            <tr>
                              <td colspan="2"><b>PRECIO DE COMPRA KG </b></td>
                              <td><b> S/ {{ cpGetPrecioUnit }}</b></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <CRow v-for="(prc, indPrc) in selectPrices" :key="indPrc" class="">
                      <div xs="12" sm="12" md="12" lg="12" class="text-center w-100">
                        <b>{{ prc.type.name }}</b>
                      </div>
                      <div xs="12" sm="12" md="4" lg="4" class="ml-2">
                        <CInput
                          label="Pr. Mayorista"
                          placeholder="0,00"
                          v-model="prc.price_sale_may"
                        />
                      </div>
                      <div xs="12" sm="12" md="4" lg="4" class="ml-2">
                        <CInput
                          label="Pr. Minorista"
                          placeholder="0,00"
                          v-model="prc.price_sale_min"
                        />
                      </div>
                      <div xs="12" sm="12" md="4" lg="4" class="ml-2">
                        <CInput
                          label="Pr. Otros"
                          placeholder="0,00"
                          v-model="prc.price_sale_otro"
                        />
                      </div>
                    </CRow>

                    <div xs="12" sm="12" md="12" lg="12" class="text-center">
                      <button :disabled="item.save" type="button" @click="mtdSavePrice(index)" class="btn btn-sm btn-info"><i class="fas fa-save"></i> Guardar precio de compra</button>
                    </div>
                  </CTab>
                </CTabs>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  name: "c-proccess-editprices",
  components: { cSelectForm },
  data() {
    return {
      hoy:null,
      purchase: [],
      keyPurchaseSelected: null,
      selectPurchase: null,
      selectProduct:0,
      selectPrices:[]
    };
  },
  created() {
    this.mtdGetData();
  },
  computed: {
    cpGetTotalInversion(){
      let tot = 0;
      if (this.selectProduct != null) {
        let payload = this.selectPurchase.purchasedetail[this.selectProduct];
        payload.gasto.forEach(element => {
          if (element.amount > 0) {
            tot += parseFloat(element.amount); 
          }
        });
        this.selectPurchase.purchasedetail[this.selectProduct].inversion = parseFloat(tot);
      }
      return parseFloat(tot).toFixed(2);
    },
    cpGetPesoNeto(){
      if (this.selectProduct != null) {
        let payload = this.selectPurchase.purchasedetail[this.selectProduct];
        return parseFloat(payload.neto).toFixed(2);
      }
    },
    cpGetPrecioUnit(){
      let payload = this.selectPurchase.purchasedetail[this.selectProduct];
      let prc = parseFloat(payload.inversion / payload.neto)
      this.selectPurchase.purchasedetail[this.selectProduct].precioUnit = parseFloat(prc);
      return parseFloat(prc).toFixed(2);
    }
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtdGetData: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/process/editprice/" +
          this.$store.getters.get_campu.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.purchase = response.data.purchase;
          this.hoy=response.data.hoy;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdGetClasesDispatch: function (state, pos) {
      let clases = "";
      if (pos == this.keyPurchaseSelected) {
        clases = "mt-1 list-group-item list-group-item-action active";
      } else {
        switch (state) {
          case 1:
            clases = "mt-1 list-group-item list-group-item-primary";
            break;
          default:
            break;
        }
      }
      return clases;
    },
    mtdSelectDispatch: function (payload, pos) {
      if (pos != this.keyPurchaseSelected) {
        this.keyPurchaseSelected = pos;
        this.selectPurchase = payload;
        this.mtdCastGasto();
        this.mtdSelectProduct(0);
      }
    },
    mtdCastGasto: function(){
      this.selectPurchase.purchasedetail.forEach(element => {
        element.gasto.push({
          amount:element.precioUnit,
          bill:{id:0,name:"COSTO DE PRODUCTO ",price:element.precioUnit},
        });
      });
    },
    mtdSelectProduct: function (pos){
      this.selectProduct=pos;
      this.selectPrices = this.selectPurchase.purchasedetail[pos].product.prices;
    },
    mtdSavePrice: function(pos){
      this.post({
        url:
          this.$store.getters.get__url +
          "/process/editprice/commit",
        token: this.$store.getters.get__token,
        params:{
          purchase:this.selectPurchase,
          pos:pos,
          prices: this.selectPrices
        }
      })
        .then((response) => {
          if (response.statusCode == 200) {
            this.selectPurchase.purchasedetail[pos].save=true;
            bus.$emit("alert", {
              color: "success",
              message: response.message,
            });
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>