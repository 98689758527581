<template>
    <div>
        <CRow class="mt-3">
            <CCol lg="3" xl="3" md="12" sm="12" xs="12">
                <CCard>
                    <CCardHeader class="d-flex justify-content-between">
                        <span><i class="fas fa-dolly"></i>&nbsp; DEVOLUCIONES</span>
                        <button
                         @click="mtdBack"
                         type="button"
                         class="btn btn-sm text-white"
                         style="background: #926cdc"
                        >
                            <i class="fas fa-hand-point-left"></i> Retroceder
                        </button>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol xs="12" sm="12" md="12" lg="12" class="text-center" v-if="(sales.length === 0)">
                                <h4>NO HAY VENTAS REALIZADAS</h4>
                            </CCol>
                            <CCol xs="12" sm="12" md="12" lg="12" v-else>
                                <ul class="list-group">
                                    <li
                                     v-for="(item, index) in sales"
                                     :key="index"
                                     :class="mtdGetClasesSales(item.state, index)"
                                     @click="mtdSelectSales(item, index)"
                                    >
                                        {{ item.name }}
                                    </li>
                                </ul>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg="9" xl="9" md="12" sm="12" xs="12" v-if="this.salesSelected.id">
                <CCard>
                    <CCardHeader class="d-flex justify-content-between">
                        <span>
                            <i class="fas fa-bars"></i>&nbsp; DETALLE DE VENTA
                            {{ this.salesSelected.id ? "DE: " + this.salesSelected.name +" - "+ this.salesSelected.voucher[0]['serie'] +" - "+ this.salesSelected.voucher[0]['vaucher_document']: "" }}
                        </span>
                        <button
                            @click="mtdCancelarSale"
                            type="button"
                            class="btn btn-sm text-white btn-info"
                            style="background: #926cdc"
                            >
                            <i class="fas fa-plus"></i> Devolver
                        </button>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol xs="12" sm="12" md="12" lg="12">
                                <div class="position-relative table-responsive">
                                    <table class="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Fecha</th>
                                                <th>Producto</th>
                                                <th>Tipo (Vivo/Beneficiado)</th>
                                                <th>Cantidad</th>
                                                <th>Precio</th>
                                                <th>Subtotal</th>
                                            </tr>
                                        </thead>
                                        <tbody class="position-relative">
                                            <tr
                                                v-for="(item, index) in salesSelected.detail"
                                                :key="index"
                                            >
                                            <td>{{ index + 1 }}</td>
                                                <td v-if="(item.sale_id == salesSelected.id)">{{ formatDate(salesSelected.date) }}</td>
                                                <td>{{ item.product.name }}</td>
                                                <td>{{ item.type }}</td>
                                                <td>{{ item.quantity }} KG</td>
                                                <td>S/{{ parseFloat(item.price_sale).toFixed(2) }}</td>
                                                <td>S/{{ parseFloat(item.quantity * item.price_sale).toFixed(2) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CModalForm
            :backdrop="true"
            :closeOnBackdrop="true"
            :size="'md'"
            :title="'Cancelar Venta'"
            :button="cp_button"
            :show.sync="modal.modal_form"
            :color="'info'"
            :centered="true"
            @mtd_action = "mtdGuardarCancelarSale"
        >
            <CRow>
                <CCol lg="12" xl="12" md="12" sm="12" xs="12" class="text-center text-bold">
                    <div class="position-relative table-responsive">
                        <table class="table table-sm table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th style="background-color: #95c799; color: #fff;">Cantidad</th>
                                    <th style="background-color: #c798c6; color: #fff;">Subtotal</th>
                                </tr>
                            </thead>
                            <tbody class="position-relative">
                                <tr  v-for="(item, index) in salesSelected.detail" :key="index">
                                    <td>{{ item.quantity }} KG</td>
                                    <td>S/{{ parseFloat(item.quantity * item.price_sale).toFixed(2) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </CCol>
                <CCol lg="12" xl="12" md="12" sm="12" xs="12" class="text-bold mt-2">
                    <CInput
                        label="Cantidad a devolver (kg)"
                        v-model="cabDevolucion.montoDevolucion"
                        placeholder="Cantidad a devolver (kg)"
                    />
                </CCol>
                <CCol lg="12" xl="12" md="12" sm="12" xs="12" class="text-bold mt-2">
                    <CInput
                        label="Monto a devolver (S/)"
                        placeholder="Monto a devolver (S/)"
                        :value.sync="cpSubtotalDevolution"
                        disabled
                    />
                </CCol>
            </CRow>
        </CModalForm>
    </div>
</template>
<script>
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
    components: { CModalForm },
    data(){
        return{
            sales: [],
            keySalesSelected: null,
            salesSelected: [],
            modal: {
                modal_form: false,
            },
            cabDevolucion:{
                montoDevolucion: "",
                sale_id: "",
                sale_detail_id: "",
            },
        }
    },
    created() {
        this.mtdGetdata();
    },
    computed: {
        cpSubtotalDevolution(){
            let devolver = 0;
            this.sales.forEach((element) =>{
                if(this.salesSelected.id == element.id){
                    element.detail.forEach((ele) =>{
                        devolver = ele.price_sale * this.cabDevolucion.montoDevolucion;
                    });
                }
            });
            return parseFloat(devolver).toFixed(2);
        },
        cp_button: function () {
            if (this.cabDevolucion.montoDevolucion != "") return false;
            return true;
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdBack: function () {
            this.$emit("mtdBack");
        },
        mtdGetdata: function (){
            this.get({
                url: this.$store.getters.get__url + "/process/devolution/" + this.$store.getters.get_campu.id,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.sales = response.data.sale;
                // console.log(this.sales);
            })
            .catch((errors) => {});
        },
        mtdSelectSales: function (payload, pos) {
            this.keySalesSelected = pos;
            this.salesSelected = payload;
            this.cabDevolucion.sale_id = payload.id;
            this.cabDevolucion.sale_detail_id = payload.detail[0].id;
        },
        mtdGetClasesSales: function (state, pos) {
            let clases = "";
            if (pos == this.keySalesSelected) {
                clases = "list-group-item list-group-item-action active";
            } else {
                switch (state) {
                case 1:
                    clases = "list-group-item list-group-item-primary";
                    break;

                default:
                    break;
                }
            }
            return clases;
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                timeZone: 'UTC'
            }).replace(/\//g, '-').split('/').reverse().join('-');
        },
        mtdCancelarSale: function (state, pos){
            this.modal.modal_form = true;
        },
        mtdGuardarCancelarSale: function (){
            this.post({
                url:
                this.$store.getters.get__url + "/process/devolution/store",
                token: this.$store.getters.get__token,
                params: this.cabDevolucion,
            })
            .then((response) => {
                this.modal.modal_form = false;
                if (!response.error){
                    this.cabDevolucion = {
                        montoDevolucion: "",
                        sale_id: "",
                        sale_detail_id: "",
                    };
                    bus.$emit("alert", {
                        color: "success",
                        message: "Solicitud de cancelación enviada!!",
                    });
                }else {
                    bus.$emit("alert", {
                        color: "warning",
                        message: response.message,
                    });
                }
            })
            .catch((errors) => {
            });
        },
    },
};
</script>