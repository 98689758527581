<template>
  <div>
    <CRow class="mt-1">
      <CCol lg="12" xl="12" md="12" sm="12" xs="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-dolly"></i>&nbsp; FLUJO DE CAMAL</span>
            <button
              @click="mtdBack"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <CCard>
              <CCardBody>
                <CRow class="mt-1">
                  <CCol sm="12" xs="12" md="3" lg="3" xl="3">
                    <CInput type="date" label="Desde" v-model="filter.f_inicio"/>
                  </CCol>
                  <CCol sm="12" xs="12" md="3" lg="3" xl="3">
                    <CInput type="date" label="Hasta" v-model="filter.f_final"/>
                  </CCol>
                  <CCol sm="12" xs="12" md="3" lg="3" xl="3">
                    <div style="margin-top:8px">&nbsp;</div>
                    <button 
                      type="button" 
                      style="background: #926cdc"
                      :class="btnClasses"
                      @click="loadTable"
                    >
                      <i class="fas fa-search"></i>&nbsp; Buscar
                    </button>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CRow>
              <CCol xs="12" sm="12" md="6" lg="6">
                <CCard>
                  <CCardHeader class="d-flex justify-content-between">
                    <span><i class="fas fa-boxes"></i>&nbsp; DETALLE DE COMPRA</span>
                  </CCardHeader>
                  <CCardBody>
                    <CChartBar
                      style="height:300px"
                      :datasets="dataCamalPur"
                      :labels="['DETALLES']"
                      :options="{ maintainAspectRatio: false }"
                    />
                  </CCardBody>
                </CCard>
              </CCol>
              <CCol xs="12" sm="12" md="6" lg="6">
                <CCard>
                  <CCardHeader class="d-flex justify-content-between">
                    <span><i class="fas fa-dolly"></i>&nbsp; DETALLE DE DESPACHO</span>
                  </CCardHeader>
                  <CCardBody>
                    <CChartBar
                      style="height:300px"
                      :datasets="dataCamalDisp"
                      :labels="['DETALLES']"
                      :options="{ maintainAspectRatio: false }"
                    />
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
  import { CChartPie, CChartBar } from '@coreui/vue-chartjs'
  import { mapActions } from "vuex";
  import moment from "moment";
  import { bus } from "../../main";

  export default {
    components: { CChartPie, CChartBar },
    data(){
      return {
        camalPur: [],
        camalDisp: [],
        filter: {
          f_inicio: null,
          f_final: null,
        },
      }
    },
    computed: {
      btnClasses() {
        return [`btn text-white w-100`];
      },
      dataCamalPur (){
        const hasCamalPurcharseData = this.camalPur.quantity_kg > 0 || this.camalPur.peso_neto > 0;
        return [
          {
            label: 'CANTIDAD KG',
            backgroundColor: hasCamalPurcharseData ? '#96d8e9' : '#CCCCCC',
            data: hasCamalPurcharseData ? [this.camalPur.quantity_kg] : [1],
          },
          {
            label: 'PESO NETO',
            backgroundColor: hasCamalPurcharseData ? '#e996d8' : '#CCCCCC',
            data: hasCamalPurcharseData ? [this.camalPur.peso_neto] : [1],
          }
        ];
      },
      dataCamalDisp (){
        const hasCamalDispatchData = this.camalDisp.quantity_kg > 0 || this.camalDisp.peso_neto > 0;
        return [
          {
            label: 'CANTIDAD KG',
            backgroundColor: hasCamalDispatchData ? '#96afe9' : '#CCCCCC',
            data: hasCamalDispatchData ? [this.camalDisp.quantity_kg] : [1],
          },
          {
            label: 'PESO NETO',
            backgroundColor: hasCamalDispatchData ? '#d096e9' : '#CCCCCC',
            data: hasCamalDispatchData ? [this.camalDisp.peso_neto] : [1],
          }
        ];
      },
    },
    created() {
      moment.locale("es");
      this.filter.f_inicio =
        moment().format("L").substr(6, 4) +
        "-" +
        moment().format("L").substr(3, 2) +
        "-" +
        moment().format("L").substr(0, 2);
      this.filter.f_final = this.filter.f_inicio;
    },
    methods: {
      ...mapActions(["get", "post"]),
      mtdBack: function () {
        this.$emit("mtdBack");
      },
      loadTable: function () {
        let f_inicio = this.filter.f_inicio;
        let f_final = this.filter.f_final;
        if (f_inicio && f_final) {
          this.mtd_getdata(f_inicio, f_final);
          this.mtd_getdata_2(f_inicio, f_final);
        } 
      },
      mtd_getdata: function (f_inicio, f_final) {
        this.get({
          url:
            this.$store.getters.get__url +
            "/process/" +
            f_inicio +
            "/" +
            f_final +
            "/flujoCamalPur",
          token: this.$store.getters.get__token,
        })
        .then((response) => {
          this.camalPur = response.data.camalPur;
          // console.log(this.camalPur);
        })
        .catch((errors) => {
        });
      },
      mtd_getdata_2: function (f_inicio, f_final) {
        this.get({
          url:
            this.$store.getters.get__url +
            "/process/" +
            f_inicio +
            "/" +
            f_final +
            "/flujoCamalDisp",
          token: this.$store.getters.get__token,
        })
        .then((response) => {
          this.camalDisp = response.data.camalDisp;
          // console.log(this.camalDisp);
        })
        .catch((errors) => {
        });
      },
    },
  }
</script>