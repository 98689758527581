<template>
    <div>
        <CRow>
            <CCol xs="12" sm="12" md="4" lg="4" xl="4">
                <CCard>
                    <CCardHeader class="d-flex justify-content-between">
                        <span><i class="fas fa-dolly"></i>&nbsp; CREDITOS</span>
                        <button
                            @click="mtdBack"
                            type="button"
                            class="btn btn-sm text-white"
                            style="background: #926cdc"
                        >
                            <i class="fas fa-hand-point-left"></i> Retroceder
                        </button>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol xs="12" sm="12" md="12" lg="12">
                                <cSelectForm 
                                    :options ="dataClients"
                                    label="Seleccione un cliente"
                                    placeholder="Escoja un cliente"
                                    :value.sync="rp_credit.user_id"
                                    @change="form_selected"
                                />
                            </CCol>
                            <CCol xs="12" sm="12" md="12" lg="12">
                                <button
                                    type="button"
                                    style="background: #926cdc"
                                    :class="btnClasses"
                                    @click="loadTable"
                                    :disabled="vue.estado == 1 ? true : false"
                                >
                                <i class="fas fa-search"></i>&nbsp; Buscar
                                </button>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol v-if="vue.exp" xs="12" sm="12" md="8" lg="8" xl="8">
                <CCard>
                    <CCardHeader class="d-flex justify-content-between">
                        <span><i class="fas fa-bars"></i>&nbsp; DETALLE DE CREDITO</span>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol xs="12" sm="12" md="4" lg="4" >
                                <button
                                type="button"
                                class="btn btn-sm text-white btn-info w-100 mb-2"
                                >
                                TOTAL: S/{{ this.creditSelected.total }}
                                </button>
                            </CCol>
                            <CCol xs="12" sm="12" md="4" lg="4">
                                <button
                                type="button"
                                class="btn btn-sm text-white btn-success w-100 mb-2"
                                >
                                PAGADO: S/{{ this.creditSelected.amount }}
                                </button>
                            </CCol>
                            <CCol xs="12" sm="12" md="4" lg="4">
                                <button
                                type="button"
                                class="btn btn-sm text-white btn-danger w-100 mb-2"
                                >
                                PENDIENTE: S/{{ this.creditSelected.pending }}
                                </button>
                            </CCol>
                        </CRow>
                        <CRow style="margin-top: 20px">
                            <CCol xs="12" sm="12" md="12" lg="12">
                                <div class="position-relative table-responsive">
                                    <table class="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Fecha</th>
                                                <th>Descripción</th>
                                                <th>Monto</th>
                                                <th>Saldo</th>
                                            </tr>
                                        </thead>
                                        <tbody class="position-relative">
                                            <tr 
                                                v-if="this.creditSelectedCharge.length === 0"
                                                :style="{ background: '#FFC6C6', textAlign: 'center'}"
                                            >
                                                <td colspan="5">Aún no hay pagos realizados</td>
                                            </tr>
                                            <tr
                                                v-else
                                                v-for="(item, index) in creditSelectedCharge"
                                                :key="index"  
                                                :style="{ background: '#FFC6C6' }"
                                            >   
                                                <td>{{ index+1 }}</td>
                                                <td>{{ formatDate(item.date) }}</td> 
                                                <td> PAGO </td>
                                                <td>S/{{ item.amount }}</td>
                                                <td>S/{{ calculatePendingAmount(item, index) }}</td>                                
                                            </tr>
                                            <tr :style="{ background: '#C6F7D0' }">
                                                <td>#</td>
                                                <td>{{ formatDate(this.creditSelected.date) }}</td>
                                                <td>CREDITO</td>
                                                <td>S/{{ this.creditSelected.total }}</td>
                                                <td>S/{{ this.creditSelected.total }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
    import cSelectForm from "../../components/shared/inputs/cSelect.vue";
    import { mapActions } from "vuex";
    import { bus } from "../../main";

    export default{
        components: {cSelectForm},
        data () {
            return {
                vue: {
                    exp: false,
                    texto: "",
                    estado: 1,
                },
                creditSelected: [],
                creditSelectedCharge: [],
                pendingAmounts: [],
                dataClients: [],
                filter: {
                    client: null,
                },
                rp_credit: {
                    user_id: "",
                },
            };
        },
        computed: {
            btnClasses() {
                return [`btn text-white w-100`];
            },
        },
        created() {
            this.mtd_getdata_typespermissions();
        },
        methods: {
            ...mapActions(["get", "post"]),
            mtdBack: function () {
                this.$emit("mtdBack");
            },
            form_selected: function () {
                this.vue.estado = 0;
            },
            mtd_getdata_typespermissions: function () {
                this.get({
                    url: this.$store.getters.get__url + "/process/creditClient",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    response.data.clientes.forEach((element) => {
                        this.dataClients.push({
                            label: element.name,
                            value: element.id,
                        });
                    });
                })
                .catch((errors) => {
                });
            },
            mtd_getdata: function (user){
                this.get({
                    url:  this.$store.getters.get__url + "/process/credit/"+ user +"/creditTable",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.creditSelected = response.data.credit;
                    this.creditSelectedCharge = response.data.creditCharge;
                })
                .catch((errors) => {
                });
            },
            loadTable: function (){
                let user = this.rp_credit.user_id;
                if (user) {
                    this.vue.exp = true;
                } else {
                    this.vue.exp = false;
                }
                this.mtd_getdata(user)
            },
            formatDate(dateString) {
                const date = new Date(dateString);
                return date.toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    timeZone: 'UTC'
                }).replace(/\//g, '-').split('/').reverse().join('-');
            },
            calculatePendingAmount(item, index) {
                const total = parseFloat(this.creditSelected.total) || 0;
                const itemAmount = parseFloat(item.amount) || 0;
                const previousPayments = this.creditSelectedCharge.slice(0, index).reduce((acc, current) => acc + parseFloat(current.amount) || 0, 0);
                const pendingAmount = Math.max(0, total - previousPayments - itemAmount).toFixed(2);
                this.pendingAmounts.push(pendingAmount);
                return pendingAmount;
            }
        },
    }
</script>