<template>
  <div>
    <CRow class="mt-3">
      <CCol lg="3" xl="3" md="12" sm="12" xs="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-dolly"></i>&nbsp; MIS CREDITOS</span>
            <button
              @click="mtdBack"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="12" class="text-center" v-if="(credits.length === 0)">
                <h4>NO HAY CREDITOS REALIZADOS</h4>
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12" v-else>
                <ul class="list-group">
                  <li
                    v-for="(item, index) in credits"
                    :key="index"
                    :class="mtdGetClasesCredit(item.state, index)"
                    @click="mtdSelectCredit(item, index)"
                  >
                    {{ item.client.name }}
                  </li>
                </ul>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol
        lg="8"
        xl="8"
        md="12"
        sm="12"
        xs="12"
        v-if="this.creditSelected.id"
      >
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span
              ><i class="fas fa-bars"></i>&nbsp; DETALLE DE CREDITO
              {{
                this.creditSelected.id
                  ? "DE: " +
                    this.creditSelected.client.name 
                  : ""
              }}</span
            >
            <button
              @click="mtdAgregarPago"
              type="button"
              class="btn btn-sm text-white btn-info"
              style="background: #926cdc"
            >
            <i class="fas fa-plus"></i> Pago
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="4" lg="4" >
                <button
                  type="button"
                  class="btn btn-sm text-white btn-info w-100 mb-2"
                >
                  TOTAL: S/{{ this.creditSelected.total }}
                </button>
              </CCol>
              <CCol xs="12" sm="12" md="4" lg="4">
                <button
                  type="button"
                  class="btn btn-sm text-white btn-success w-100 mb-2"
                >
                  PAGADO: S/{{ this.creditSelected.amount }}
                </button>
              </CCol>
              <CCol xs="12" sm="12" md="4" lg="4">
                <button
                  type="button"
                  class="btn btn-sm text-white btn-danger w-100 mb-2"
                >
                  PENDIENTE: S/{{ this.creditSelected.pending }}
                </button>
              </CCol>
            </CRow>
            <CRow style="margin-top: 20px">
              <CCol xs="12" sm="12" md="12" lg="12">
                <div class="position-relative table-responsive">
                  <table class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Fecha</th>
                        <th>Producto</th>
                        <th>Tipo (Vivo/Beneficiado)</th>
                        <th>Cantidad</th>
                        <th>Precio</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody class="position-relative">
                      <tr
                        v-for="(item, index) in creditSelected.sale.detail"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td v-if="(item.sale_id == creditSelected.sale.id)">{{ formatDate(creditSelected.sale.date) }}</td>
                        <td>{{ item.product.name }}</td>
                        <td>{{ item.type }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.price_sale}}</td>
                        <td>{{ item.quantity * item.price_sale }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- modal -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="'Agregar pago'"
      :button="cp_button"
      :show.sync="modal.modal_form"
      :color="'info'"
      :centered="true"
      @mtd_action = "mtd_guardar_pago"
    >
      <CRow>
        <CCol
          lg="12"
          xl="12"
          md="12"
          sm="12"
          xs="12"
          class="text-center text-bold"
        >
          <h5>Pendiente: S/{{ this.creditSelected.pending }}</h5>
        </CCol>
        <CCol
          lg="4"
          xl="4"
          md="12"
          sm="12"
          xs="12"
          class="d-flex justify-content-evenly"
          v-for="(item, index) in method"
          :key="index"
          @click="mtdSelectMethod(item.id)"
        >
          <CButton
            :class="
              cabPago.method == item.id
                ? 'w-100 btn btn-sm btn-success'
                : 'w-100 btn btn-sm btn-outline-success'
              "
          >
            <i class="fas fa-donate"></i> {{ item.name }}
          </CButton>
        </CCol>
        <CCol
          lg="12"
          xl="12"
          md="12"
          sm="12"
          xs="12"
          class="text-bold mt-2"
        >
          <CInput
            label="Num operación"
            v-model="cabPago.numOpe"
            placeholder="Número de operación"
            :disabled="disableOpe"
          />
        </CCol>
        <CCol
          lg="12"
          xl="12"
          md="12"
          sm="12"
          xs="12"
          class="text-bold mt-2"
        >
          <CInput
            label="Monto a pagar"
            v-model="cabPago.montoPago"
            placeholder="Monto a pagar"
          />
        </CCol>
      </CRow>
    </CModalForm>
  </div>
</template>

<script>
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  components: { CModalForm },
  data(){
    return{
      credits: [],
      dealer: [],
      method: [],
      keyCreditSelected: null,
      disableOpe: true,
      creditSelected: [],
      modal: {
        modal_form: false,
      },
      cabPago:{
        method: 0,
        numOpe: "",
        montoPago: "",
        credit_id: "",
      },
    };
  },
  created() {
    this.mtdGetdata();
  },
  computed: {
    cp_button: function () {
      if (this.cabPago.montoPago != "") return false;
      return true;
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtdGetdata: function (){
      this.get({
        url:
          this.$store.getters.get__url +
          "/process/credit/" +
          this.$store.getters.get_campu.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.credits = response.data.credit;
          this.method = response.data.method;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdSelectCredit: function (payload, pos) {
      this.keyCreditSelected = pos;
      this.creditSelected = payload;
      this.cabPago.credit_id = payload.id;
    },
    mtdGetClasesCredit: function (state, pos) {
      let clases = "";
      if (pos == this.keyCreditSelected) {
        clases = "list-group-item list-group-item-action active";
      } else {
        switch (state) {
          case 1:
            clases = "list-group-item list-group-item-primary";
            break;

          default:
            break;
        }
      }
      return clases;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: 'UTC'
      }).replace(/\//g, '-').split('/').reverse().join('-');
    },
    mtdAgregarPago: function(){
      this.modal.modal_form = true;
    },
    mtd_guardar_pago: function(){
      this.post({
        url:
          this.$store.getters.get__url +
          "/process/credit/store",
        token: this.$store.getters.get__token,
        params: this.cabPago,
      })
      .then((response) => {
        this.modal.modal_form = false;
        this.creditSelected.total = response.data.total;
        this.creditSelected.amount = response.data.amount;
        this.creditSelected.pending = response.data.pending;
        if (!response.error){
          this.cabPago = {
            method: 0,
            numOpe: "",
            montoPago: "",
            credit_id: "",
          };
          (this.keyCreditSelected = null),
          (this.disableOpe = true),
          this.mtdGetdata();
          bus.$emit("alert", {
            color: "success",
            message: "Pago registrado!!",
          });
        }else {
          bus.$emit("alert", {
            color: "warning",
            message: response.message,
          });
        }
      })
      .catch((errors) => {
        // this.errorsBackend = errors;
        // this.$emit("error", this.event);
      });
    },
    mtdSelectMethod: function (method){
      this.cabPago.method = method;
      if (method > 1) {
        this.disableOpe = false;
      } else {
        this.disableOpe = true;
      } 
    },
  },
};
</script>

<style lang="scss" scoped>
</style>