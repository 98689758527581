<template>
  <div>
    <CRow class="mt-3">
      <CCol lg="3" xl="3" md="12" sm="12" xs="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span
              ><i class="fas fa-dolly"></i>&nbsp; DEVOLUCIONES PENDIENTES</span
            >
            <button
              @click="mtdBack"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol
                xs="12"
                sm="12"
                md="12"
                lg="12"
                class="text-center"
                v-if="sales.length === 0"
              >
                <h4>NO HAY DEVOLUCIONES PENDIENTES</h4>
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12" v-else>
                <ul class="list-group">
                  <li
                    v-for="(item, index) in sales"
                    :key="index"
                    :class="mtdGetClasesSales(item.state, index)"
                    @click="mtdSelectSales(item, index)"
                  >
                    {{ item.sale.client.name }}
                  </li>
                </ul>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol lg="9" xl="9" md="12" sm="12" xs="12" v-if="salesSelected != null">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-dolly"></i>&nbsp; DETALLE</span>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <!-- {{ salesSelected }} -->
              <CCol lg="2" xl="2" md="12" sm="12" xs="12">
                <b>Producto : {{ salesSelected.sale_detail.product.name }}</b>
              </CCol>
              <CCol lg="2" xl="2" md="12" sm="12" xs="12">
                <b>Kg a devolver: {{ salesSelected.amount }}Kg</b>
              </CCol>
              <CCol lg="3" xl="3" md="12" sm="12" xs="12">
                <b
                  >Soles a devolver: S/.
                  {{
                    parseFloat(
                      salesSelected.amount *
                        salesSelected.sale_detail.price_sale
                    ).toFixed(2)
                  }}</b
                >
              </CCol>
              <CCol lg="5" xl="5" md="12" sm="12" xs="12">
                <b>Repartidor: {{ salesSelected.sale.user.name }}</b>
              </CCol>
              <CCol
                lg="6"
                xl="6"
                md="12"
                sm="12"
                xs="12"
                class="text-center mt-2"
              >
                <button
                  @click="mtdCancelar"
                  class="btn btn-sm w-100 btn-danger"
                >
                  <i class="fas fa-times"></i> Rechazar
                </button>
              </CCol>
              <CCol
                lg="6"
                xl="6"
                md="12"
                sm="12"
                xs="12"
                class="text-center mt-2"
              >
                <button @click="mtdSave" class="btn btn-sm w-100 btn-info ml-2">
                  <i class="fas fa-save"></i> Aceptar
                </button>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  name: "c-commitdevoluciones",
  data() {
    return {
      sales: [],
      keySalesSelected: null,
      salesSelected: null,
    };
  },
  created() {
    this.mtdGetdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtdGetdata: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/process/devolutionadmin/" +
          this.$store.getters.get_campu.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.sales = response;
          //   console.log(this.sales);
        })
        .catch((errors) => {});
    },
    mtdSelectSales: function (payload, pos) {
      this.keySalesSelected = pos;
      this.salesSelected = payload;
    },
    mtdGetClasesSales: function (state, pos) {
      let clases = "";
      if (pos == this.keySalesSelected) {
        clases = "list-group-item list-group-item-action active";
      } else {
        switch (state) {
          case 1:
            clases = "list-group-item list-group-item-primary";
            break;

          default:
            break;
        }
      }
      return clases;
    },
    mtdCancelar: function () {
      this.keySalesSelected = null;
      this.salesSelected = null;
    },
    mtdSave: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/process/devolutionadmin/store/" +
          this.$store.getters.get_campu.id,
        token: this.$store.getters.get__token,
        params: this.salesSelected,
      })
        .then((response) => {
          if (!response.error) {
            this.mtdCancelar();
            bus.$emit("alert", {
              color: "success",
              message: "Devolución aceptada!!",
            });
            this.mtdGetdata();
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: "Error al aceptar",
            })
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
              color: "danger",
              message: errors,
            })
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>