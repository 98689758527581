<template>
  <div>
    <br>
    <CRow class="float-right">
      <CCol md="12" lg="12" sm="12" xs="12" >
        <button @click="mtdBackRol" type="button" class="btn btn-sm text-white" style="background: #926cdc"><i class="fas fa-hand-point-left"></i> Cambiar de rol</button>
      </CCol>
    </CRow>
    <br>
    <CRow class="mt-3"> 
      <CCol sm="6" xs="12" md="4" lg="4" xl="2">
        <CWidgetProgressIcon
          :header="'S/'+cpTotCompras"
          text="COMPRAS"
          color="gradient-info"
          inverse
        >
          <button
            style="background: transparent; border: none; padding: 0;"
           @click="loadTablePurchase"> 
            <CIcon name="cil-cart" height="36" style="color:#ced2d8" />
          </button>
        </CWidgetProgressIcon>
      </CCol>
      <CCol sm="6" xs="12" md="4" lg="4" xl="2">
        <CWidgetProgressIcon
          :header="'S/'+cpTotalVentas"
          text="VENTAS"
          color="gradient-success"
          inverse
        >
          <button
            style="background: transparent; border: none; padding: 0;"
           @click="loadTableSales"> 
            <CIcon name="cil-dollar" height="36" style="color:#ced2d8" />
          </button>
        </CWidgetProgressIcon>
      </CCol>
      <CCol sm="6" xs="12" md="4" lg="4" xl="2">
        <CWidgetProgressIcon
          :header="'S/'+cpGanancias"
          text="GANANCIAS"
          color="gradient-warning"
          inverse
        >
          <CIcon name="cil-plus" height="36" />
        </CWidgetProgressIcon>
      </CCol>
      <CCol sm="6" xs="12" md="4" lg="4" xl="2">
        <CWidgetProgressIcon
          :header="cpKgDispatch+' Kg'"
          text="DESPACHADOS"
          color="gradient-primary"
          inverse
        >
          <CIcon name="cil-chartPie" height="36" />
        </CWidgetProgressIcon>
      </CCol>
      <CCol sm="6" xs="12" md="4" lg="4" xl="2">
        <CWidgetProgressIcon
          :header="cpKgSale+' Kg'"
          text="VENDIDOS"
          color="gradient-info"
          inverse
        >
          <CIcon name="cil-speedometer" height="36" />
        </CWidgetProgressIcon>
      </CCol>
      <CCol sm="6" xs="12" md="4" lg="4" xl="2">
        <CWidgetProgressIcon
          :header="cpKgMerma+' Kg'"
          text="MERMA"
          color="gradient-danger"
          inverse
        >
          <CIcon name="cil-speech" height="36" />
        </CWidgetProgressIcon>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name:"c-home-administrador",
  data() {
    return {
      purchase: [],
      charge:[],
      sale:[],
      dispatch:[]
    }
  },
  computed: {
    cpTotCompras(){
        let to = 0;
        this.purchase.forEach(element => {
            to += parseFloat(element.total);
        });
        return parseFloat(to).toFixed(2);
    },
    cpTotalVentas(){
        let to = 0;
        this.charge.forEach(element => {
            to += parseFloat(element.amount);
        });
        return parseFloat(to).toFixed(2);
    },
    cpGanancias(){
        let ganancia = parseFloat(this.cpTotalVentas) - parseFloat(this.cpTotCompras);
        return parseFloat(ganancia).toFixed(2);
    },
    cpKgDispatch(){
        let to = 0;
        this.dispatch.forEach(element => {
            element.detail.forEach(ele => {
                to += parseFloat(ele.total_neto);
            });
        });
        return parseFloat(to).toFixed(2);
    },
    cpKgSale(){
        let to = 0;
        this.sale.forEach(element => {
            element.detail.forEach(ele => {
                to += parseFloat(ele.quantity);
            });
        });
        return parseFloat(to).toFixed(2);
    },
    cpKgMerma(){
        let merma = parseFloat(this.cpKgDispatch) - parseFloat(this.cpKgSale);
        return parseFloat(merma).toFixed(2);
    }
  },
  created() {
    this.mtdGetData();
  },
  methods: {
    mtdBackRol: function(){
      this.$store.commit("mt_set_rol", null);
    },
    ...mapActions(["get", "post"]),
    mtdGetData: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bead/index/day/" +
          this.$store.getters.get_campu.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.purchase=response.data.purchase;
          this.charge=response.data.charge;
          this.sale=response.data.sale;
          this.dispatch=response.data.dispatch;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    loadTablePurchase: function (){
      this.vue.exp = true;
      this.vue.showPurchase = true;
      this.vue.showSales = false;
    },
    loadTableSales: function (){
      this.vue.exp = true;
      this.vue.showPurchase = false;
      this.vue.showSales = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
