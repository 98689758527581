<template>
  <div>
    <HomeProcess v-if="view == 'home'" @changePage="changePage" />
    <ComprasVue
      v-if="view == 'purchase'"
      @changePage="changePage"
      @mtdBack="mtdBack"
    />
    <DespachosVue v-if="view == 'dispatch'" @changePage="changePage" @mtdBack="mtdBack"/>
    <SaleVue v-if="view =='sale'" @changePage="changePage" @mtdBack="mtdBack" :warehouse="1"/>
  </div>
</template>
    
    <script>
import HomeProcess from "./HomeProcess.vue";
import ComprasVue from "../Process/Compras.vue";
import DespachosVue from "../Process/Despachos.vue";
import SaleVue from '../Process/SaleStock.vue';

export default {
  name: "c-process",
  data() {
    return {
      view: "home",
    };
  },
  components: {
    HomeProcess,
    ComprasVue,
    DespachosVue,
    SaleVue
  },
  methods: {
    changePage: function (view) {
      this.view = view;
      this.$emit("mtdViewFooter", false, "process");
    },
    mtdBack: function () {
      this.view = "home";
      this.$emit("mtdViewFooter", true, "process");
    },
  },
};
</script>
    
    
