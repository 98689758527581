<template>
  <div>
    <div calss="">
      <HomeDealer v-if="view == 'home'" />
      <ProccessIndex v-if="view == 'process'" @mtdViewFooter="mtdViewFooter" />
      <ReportIndex v-if="view == 'report'" @mtdViewFooter="mtdViewFooter" />
    </div>
    <FooterVue :view="view" @setView="setView" :booFooter="booFooter" />
  </div>
</template>

<script>
import FooterVue from '../../components/camal/Footer.vue';
import ProccessIndex from "../../components/camal/process.vue";
import ReportIndex from "../../components/camal/report.vue";
import HomeDealer from '../../components/camal/homeDealer.vue';

export default {
  name: "v-dealer-index",
  components: {
    FooterVue,
    ProccessIndex,
    HomeDealer,
    ReportIndex
  },
  data() {
    return {
      view: "home",
      booFooter: true,
    };
  },
  methods: {
    setView: function (payload) {
      this.view = payload;
    },
    mtdViewFooter: function (boo, view) {
      this.booFooter = boo;
      if (boo == true) this.view = view;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>