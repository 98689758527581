<template>
  <div>
    <div class="" v-if="$store.getters.get_campu == null || $store.getters.get_rol == null">
      <CRow v-if="$store.getters.get_campu == null">
        <CampusVue :campus="cpCam"  @mtdSetCampu="mtdSetCampu" />
      </CRow>
      <CRow v-else>
        <RolVue :roles="roles" @mtdSetRol="mtdSetRol" />
      </CRow>
    </div>
    <div v-else>
      <AdministradorVue v-if="$store.getters.get_rol.id == 1" />
      <DealerVue v-if="$store.getters.get_rol.id == 2" />
      <CamalVue v-if="$store.getters.get_rol.id == 3" />
    </div>
  </div>
</template>

<script>
import CampusVue from '../components/home/Campus.vue';
import RolVue from '../components/home/Rol.vue';

import AdministradorVue from '../views/administrador/index.vue';
import DealerVue from '../views/Dealer/index.vue'
import CamalVue from '../views/Camal/index.vue'

export default {
  name: 'Dashboard',
  components:{CampusVue,RolVue,AdministradorVue,DealerVue, CamalVue},
  data() {
    return {
      roles:[],
      rol:null,
      campus:[],
    }
  },
  computed: {
    cpCam(){
      return this.$store.getters.get_campus;
    }
  },
  created() {
    this.roles = this.$store.getters.get_roles; 
    if(this.$store.getters.get_all_user.roles.length == 1){
      this.rol = this.$store.getters.get_all_user.roles[0].role_id
    }else{
      this.rol =null;
    }
  },
  methods:{
    mtdSetRol: function(payload){
      this.$store.commit("mt_set_rol", payload);
    },
    mtdSetCampu: function(payload){
      this.$store.commit("mt_set_campu", payload);
    }
  }
}
</script>
