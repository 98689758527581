<template>
  <div>
    <CRow class="mt-1">
      <CCol lg="3" xl="3" md="12" sm="12" xs="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-dolly"></i>&nbsp; STOCK</span>
            <button
              @click="mtdBack"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol
                xs="12"
                sm="12"
                md="12"
                lg="12"
                class="text-center"
                v-if="dispatch.length === 0"
              >
                <h4>NO HAY STOCK</h4>
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12">
                <ul class="list-group">
                  <li
                    v-for="(item, index) in dispatch"
                    :key="index"
                    :class="mtdGetClasesDispatch(item.state, index)"
                    @click="mtdSelectDispatch(item, index)"
                  >
                    {{ item.product.name }} {{ item.type }} ({{
                      item.amount
                    }}kg)
                  </li>
                </ul>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol
        lg="9"
        xl="9"
        md="12"
        sm="12"
        xs="12"
        v-if="proccess.detail.length > 0"
      >
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span
              ><i class="fas fa-search-dollar"></i>&nbsp; DATOS DEL
              PROCESO</span
            >
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol lg="6" xl="6" md="12" sm="12" xs="12">
                <CInput
                  size="sm"
                  label="STOCK PARA PROCESAR"
                  v-model="proccess.stock"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="6" xl="6" md="12" sm="12" xs="12">
                <CInput
                  size="sm"
                  label="KG PROCESADOS"
                  placeholder="KG PROCESADOS"
                  v-model="proccess.amountProcess"
                />
              </CCol>
              <CCol
                lg="3"
                xl="3"
                md="12"
                sm="12"
                xs="12"
                v-for="(item, index) in proccess.detail"
                :key="index"
              >
                <CInput
                  :label="item.productName"
                  size="sm"
                  placeholder="Cantidad saliente"
                  v-model="item.quantity"
                  v-on:keypress="isNumber($event)"
                />
              </CCol>
              <CCol
                v-if="proccess.detail.length > 0"
                xs="12"
                sm="12"
                md="12"
                lg="12"
              >
                <div class="d-flex justify-content-evenly">
                  <button
                    type="button"
                    class="btn btn-sm w-100 text-white btn-danger"
                    @click="mtdCancelar"
                  >
                    <i class="fas fa-times"></i>&nbsp; Cancelar
                  </button>
                  <button
                    type="button"
                    :disabled="cpButton"
                    class="btn btn-sm w-100 text-white ml-1 pl-1 btn-info"
                    @click="mtdSave"
                  >
                    <i class="fas fa-save"></i>&nbsp; Registrar Despacho
                  </button>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  name: "c-process-proccesTienda",
  created() {
    this.mtdGetdata();
  },
  data() {
    return {
      dispatch: [],
      derivados: [],
      selectDispatch: null,
      keyDispatchSelected: null,
      proccess: {
        stockId: 0,
        stock: 0,
        amountProcess: 0,
        detail: [],
      },
    };
  },
  computed: {
    cpButton() {
      if (this.proccess.amountProcess > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtdGetdata: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/process/tienda/" +
          this.$store.getters.get_campu.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.dispatch = response.data.stock;
          this.derivados = response.data.derivados;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdGetClasesDispatch: function (state, pos) {
      let clases = "";
      if (pos == this.keyDispatchSelected) {
        clases = "list-group-item list-group-item-action active";
      } else {
        switch (state) {
          case 1:
            clases = "list-group-item list-group-item-primary";
            break;
          default:
            break;
        }
      }
      return clases;
    },
    mtdSelectDispatch: function (payload, pos) {
      this.keyDispatchSelected = pos;
      this.selectDispatch = payload;
      this.proccess = {
        stockId: payload.id,
        stock: payload.amount,
        amountProcess: 0,
        detail: [],
      };
      let det = [];
      this.derivados.forEach((element) => {
        det.push({
          productId: element.id,
          productName: element.name,
          quantity: 0,
        });
      });
      this.proccess.detail = det;
    },
    mtdVerifQuantity: function () {
      if (parseFloat(this.proccess.stock) < parseFloat(this.proccess.amountProcess)) {
        return true;
      } else {
        let sizeDerivados = this.derivados.length;
        let cntCeros = 0;
        this.proccess.detail.forEach((element) => {
          if (element.quantity == 0) {
            cntCeros++;
          }
        });
        if (cntCeros == sizeDerivados) {
          return true;
        } else {
          return false;
        }
      }
    },
    mtdCancelar: function () {
      this.selectDispatch = null;
      this.keyDispatchSelected = null;
      this.proccess = {
        stockId: 0,
        stock: 0,
        amountProcess: 0,
        detail: [],
      };
    },
    mtdSave: function () {
      if (this.mtdVerifQuantity()) {
        bus.$emit("alert", {
          color: "warning",
          message: "DIGITE CORRECTAMENTE LA CANTIDADES!",
        });
      } else {
        this.post({
          url:
            this.$store.getters.get__url +
            "/process/tienda/store/" +
            this.$store.getters.get_campu.id,
          token: this.$store.getters.get__token,
          params: this.proccess,
        })
          .then((response) => {
            if (!response.error) {
              bus.$emit("alert", {
                color: "success",
                message: "Proceso registrado correctamente!!",
              });
              this.mtdCancelar();
              this.mtdGetdata();
            } else {
              bus.$emit("alert", {
                color: "warning",
                message: response.message,
              });
            }
          })
          .catch((errors) => {
            // this.errorsBackend = errors;
            // this.$emit("error", this.event);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>