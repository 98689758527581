<template>
  <div>
    <CRow class="mt-1">
      <CCol lg="4" xl="4" md="12" sm="12" xs="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-dolly"></i>&nbsp; REPORTE DE COMPRAS</span>
            <button
              @click="mtdBack"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="6" lg="6">
                <CInput
                  label="Fecha Ini."
                  type="date"
                  v-model="rp_purchase.f_inicio"
                  @change="form_selected"
                />
              </CCol>
              <CCol xs="12" sm="12" md="6" lg="6">
                <CInput
                  label="Fecha Fin."
                  type="date"
                  v-model="rp_purchase.f_final"
                  @change="form_selected"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="12">
                <button
                  type="button"
                  style="background: #926cdc"
                  :class="btnClasses"
                  @click="loadTable"
                >
                  <i class="fas fa-search"></i>&nbsp; Buscar
                </button>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol v-if="vue.exp" lg="8" xl="8" md="12" sm="12" xs="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Reporte de compras"
          icon="fas fa-dolly"
          btn_name="Usuario"
          :size="'md'"
          :button_new="false"
          :actions="true"
          :withActions="'1%'"
          :buttonEdit="false"
          :buttonDelete="false"
          :myButtons="myButtons"
          @mtd_pruchasedetail="mtd_pruchasedetail"
          :buttonBack="false"
        />
      </CCol>
    </CRow>
    <!--Modal Purchase Detail-->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'xl'"
      :color="'info'"
      :centered="true"
      :show_boton="false"
      :show.sync="modal_purchasedetail.boo"
    >
      <CTableWrapper
        :items="data_detail"
        :fields="fields_detail"
        hover
        :striped="true"
        :border="true"
        small
        fixed
        caption="Detalles de compra"
        icon="fas fa-dolly"
        btn_name="Usuario"
        :size="'md'"
        :button_new="false"
        :actions="false"
        :buttonEdit="false"
        :buttonDelete="false"
        :buttonBack="false"
      />
    </CModalForm>
  </div>
</template>
<script>
const fields = [
  { key: "id", label: "Id", _style: "width:3%" },
  { key: "provider", label: "Proveedor", _style: "width:20%;" },
  { key: "user", label: "Usu. Registra", _style: "width:20%;" },
  { key: "factura", label: "N°. Documento", _style: "width:10%;" },
  { key: "date", label: "Fecha", _style: "width:5%;" },
  { key: "total", label: "Total", _style: "width:5%;" },
];
const fields_detail = [
  { key: "name", label: "Producto", _style: "width:10%" },
  { key: "quantity_unit", label: "Cantidad Unitaria", _style: "width:10%;" },
  { key: "quantity_kg", label: "Cantidad Kg.", _style: "width:10%;" },
  { key: "quantity_java", label: "Cantidad Java", _style: "width:10%;" },
  { key: "peso_java", label: "Peso Java", _style: "width:10%;" },
  { key: "peso_neto", label: "Peso Neto", _style: "width:10%;" },
  { key: "subtotal", label: "Prec. kg", _style: "width:10%;" },
  { key: "sub_total", label: "Sub Total", _style: "width:10%;" },
];

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";
import moment from "moment";

export default {
  components: {
    CTableWrapper,
    CModalForm,
  },
  data() {
    return {
      prefix: "process",
      vue: {
        exp: false,
        texto: "",
        estado: 1,
      },
      fields,
      fields_detail,
      data: [],
      data_detail: [],
      myButtons: [
        {
          class: "btn-sm",
          color: "success",
          tooltip: "Ver detalles",
          action: "mtd_pruchasedetail",
          icon: "fas fa-eye",
        },
      ],
      rp_purchase: {
        f_inicio: null,
        f_final: null,
      },
      modal_purchasedetail: {
        boo: false,
      },
    };
  },
  computed: {
    btnClasses() {
      return [`btn text-white w-100`];
    },
  },
  created() {
    moment.locale("es");
    this.rp_purchase.f_inicio =
      moment().format("L").substr(6, 4) +
      "-" +
      moment().format("L").substr(3, 2) +
      "-" +
      moment().format("L").substr(0, 2);
    this.rp_purchase.f_final = this.rp_purchase.f_inicio;
    // this.mtd_getdata(this.rp_purchase.f_inicio, this.rp_purchase.f_final);
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function (f_inicio, f_final) {
      this.get({
        url:
          this.$store.getters.get__url +
          "/process/" +
          f_inicio +
          "/" +
          f_final +
          "/purchaseReport",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.purchases;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    form_selected: function () {
      this.vue.estado = 0;
    },
    loadTable: function () {
      let f_inicio = this.rp_purchase.f_inicio;
      let f_final = this.rp_purchase.f_final;
      if (f_inicio && f_final) {
        this.vue.exp = true;
      } else {
        this.vue.exp = false;
      }
      this.mtd_getdata(f_inicio, f_final);
    },
    mtd_pruchasedetail: function (item) {
      this.get({
        url:
          this.$store.getters.get__url +
          "/process/" +
          item.id +
          "/purchaseDetailReport",
        token: this.$store.getters.get__token,
      }).then((response) => {
        this.modal_purchasedetail.boo = true;
        this.data_detail = response.data.purchaseDetail;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>