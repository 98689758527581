<template>
  <div>
    <div calss="">
      <HomeVue v-if="view == 'home'" />
      <MaintenanceVue v-if="view == 'mantenimiento'" @mtdViewFooter="mtdViewFooter" />
      <ProccessIndex v-if="view=='process'" @mtdViewFooter="mtdViewFooter" />
      <ReportVue v-if="view=='report'" @mtdViewFooter="mtdViewFooter" />
      <CajaHome v-if="view=='caja'" @mtdViewFooter="mtdViewFooter" />
    </div>
      <FooterVue :view="view" @setView="setView" :booFooter="booFooter" />
  </div>
</template>

<script>
import FooterVue from "../../components/administrador/Footer.vue";
import HomeVue from "../../components/administrador/Home.vue";
import CajaHome from "../../components/Caja/home.vue";
import ProccessIndex from '../../components/Process/index.vue';
import MaintenanceVue from "../../views/maintenance/index.vue";
import ReportVue from '../../views/report/index.vue';


export default {
  components: { FooterVue, HomeVue, MaintenanceVue,ProccessIndex,ReportVue, CajaHome },
  data() {
    return {
      view: "home",
      booFooter:true
    };
  },
  methods: {
    setView: function (payload) {
      this.view = payload;
    },
    mtdViewFooter: function(boo,view){
      this.booFooter = boo;
      if(boo == true) this.view = view;
    }
  },
};
</script>

<style lang="scss" scoped></style>
