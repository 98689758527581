<template>
  <div class="">
    <HomeReportVue v-if="page == 'home'" @changePage="changePage" />
    <PurchaseVue class="pt-1" v-if="page == 'purchases'" @mtdBack="mtdBack" />
    <DispatchVue class="pt-1" v-if="page == 'dispatch'" @mtdBack="mtdBack" />
    <FlujoCamal class="pt-1" v-if="page == 'flujo-camal'" @mtdBack="mtdBack" />
  </div>
</template>

<script>
import HomeReportVue from "../../components/camal/HomeReport.vue";
import PurchaseVue from "../../components/report/Purchase.vue"
import DispatchVue from "../../components/report/Dispatch.vue";
import FlujoCamal from "../../components/report/FlujoCamal.vue";

export default {
  components: {
    HomeReportVue,
    PurchaseVue,
    DispatchVue,
    FlujoCamal
  },
  data() {
    return {
      page: "home",
    };
  },
  methods: {
    changePage: function (page) {
      this.page = page;
      // console.log(this.page);
      this.$emit("mtdViewFooter", false, "report");
    },
    mtdBack: function () {
      this.page = "home";
      // console.log(this.page);
      this.$emit("mtdViewFooter", true, "report");
    },
  },
};
</script>
