<template>
  <div>
    <CRow class="mt-1">
      <CCol lg="5" xl="5" md="12" sm="12" xs="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span
              ><i class="fas fa-history"></i>&nbsp; DEVOLUCIONES DE
              COMPRAS</span
            >
            <button
              @click="mtdBack"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xxl="4" xl="4" lg="4" md="12" sm="12" xs="12">
                <CInput
                  label="Fecha Ini."
                  type="date"
                  v-model="filter.initial"
                />
              </CCol>
              <CCol xxl="4" xl="4" lg="4" md="12" sm="12" xs="12">
                <CInput
                  label="Fecha Ini."
                  type="date"
                  v-model="filter.end"
                  :max="hoy"
                />
              </CCol>
              <CCol xxl="4" xl="4" lg="4" md="12" sm="12" xs="12">
                <label for="">&nbsp;</label>
                <button
                  type="button"
                  style="background: #926cdc"
                  class="btn btn-sm text-white w-100"
                  @click="mtdSearchData"
                >
                  <i class="fas fa-search"></i>&nbsp; Buscar
                </button>
              </CCol>
            </CRow>
            <CRow>
              <CCol xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                <CInput
                  label="Buscar por proveedor."
                  type="text"
                  v-model="search"
                  placeholder="DIGITE PROVEEDOR"
                />
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12">
                <ul class="list-group">
                  <li
                    v-for="(item, index) in cpData"
                    :key="index"
                    :class="mtdGetActive(item)"
                    @click="mtdSelectProduct(item)"
                  >
                    <b
                      >{{ item.date }} - {{ item.document }} -
                      {{ item.provider }}</b
                    >
                  </li>
                </ul>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol lg="7" xl="7" md="12" sm="12" xs="12" v-if="purchaseIdSelect != 0">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span
              ><i class="fas fa-table"></i>&nbsp; DETALLE DE LA COMPRA -
              {{ purchaseSelect.type == 1 ? "CAMAL" : "TIENDA" }}</span
            >
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol lg="3" xl="3" md="12" sm="12" xs="12">
                <CInput
                  label="Fecha de compra"
                  :disabled="true"
                  v-model="purchaseSelect.date"
                />
              </CCol>
              <CCol lg="3" xl="3" md="12" sm="12" xs="12">
                <CInput
                  label="Documento"
                  :disabled="true"
                  v-model="purchaseSelect.document"
                />
              </CCol>
              <CCol lg="6" xl="6" md="12" sm="12" xs="12">
                <CInput
                  label="Proveedor"
                  :disabled="true"
                  v-model="purchaseSelect.provider"
                />
              </CCol>
              <CCol lg="2" xl="2" md="12" sm="12" xs="12">
                <CInput
                  label="Total de compra"
                  :disabled="true"
                  v-model="purchaseSelect.subtotal"
                />
              </CCol>
              <CCol lg="2" xl="2" md="12" sm="12" xs="12">
                <CInput
                  label="Total devuelto"
                  :disabled="true"
                  v-model="purchaseSelect.devolution"
                />
              </CCol>
              <CCol lg="2" xl="2" md="12" sm="12" xs="12">
                <CInput
                  label="Total neto"
                  :disabled="true"
                  v-model="purchaseSelect.total"
                />
              </CCol>
              <CCol lg="2" xl="2" md="12" sm="12" xs="12">
                <CInput
                  label="Total pagado"
                  :disabled="true"
                  v-model="purchaseSelect.payment"
                />
              </CCol>
              <CCol lg="2" xl="2" md="12" sm="12" xs="12">
                <CInput
                  label="Total pendiente"
                  :disabled="true"
                  v-model="purchaseSelect.pending"
                />
              </CCol>
              <CCol lg="12" xl="12" md="12" sm="12" xs="12" class="text-center">
                <b>DETALLE</b>
              </CCol>
            </CRow>
            <CRow
              v-for="(det, ind) in purchaseSelect.purchasedetail"
              :key="ind"
            >
              <CCol lg="6" xl="6" md="12" sm="12" xs="12">
                <CInput
                  label="Producto"
                  :disabled="true"
                  :value="det.productName"
                />
              </CCol>
              <CCol lg="2" xl="2" md="12" sm="12" xs="12">
                <CInput
                  label="Uni"
                  :disabled="true"
                  v-model="det.unitPurchase"
                />
              </CCol>
              <CCol lg="2" xl="2" md="12" sm="12" xs="12">
                <CInput
                  label="Kg"
                  :disabled="true"
                  v-model="det.netoPurchase"
                />
              </CCol>
              <CCol lg="2" xl="2" md="12" sm="12" xs="12">
                <CInput
                  label="P. compra kg"
                  :disabled="true"
                  v-model="det.price"
                />
              </CCol>
              <CCol lg="2" xl="2" md="12" sm="12" xs="12">
                <CInput label="Unit Devolver" v-model="det.unitDevolution" />
              </CCol>
              <CCol lg="2" xl="2" md="12" sm="12" xs="12">
                <CInput label="Kg Devolver" v-model="det.netoDevolution" @keyup="mtdSetSubtotal(ind)" />
              </CCol>
              <CCol lg="2" xl="2" md="12" sm="12" xs="12">
                <CInput label="Devolución (S/)" v-model="det.subDevolution" disabled/>
              </CCol>
              <CCol lg="12" xl="12" md="12" sm="12" xs="12">
                <hr />
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="12" xl="12" md="12" sm="12" xs="12">
                <div class="d-flex justify-content-evenly">
                  <button
                    type="button"
                    class="btn btn-sm w-100 text-white btn-danger"
                    @click="mtdCancel"
                  >
                    <i class="fas fa-times"></i>&nbsp; Cancelar
                  </button>
                  <button
                    type="button"
                    :disabled="purchaseSelect.payment > 0?false:true"
                    class="btn btn-sm w-100 text-white ml-1 pl-1 btn-info"
                    @click="mtdSave"
                  >
                    <i class="fas fa-save"></i>&nbsp; Registrar Devolución
                  </button>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import moment from "moment";

export default {
  name: "c-proccess-devolutionpurchase",
  data() {
    return {
      hoy: null,
      filter: {
        initial: null,
        end: null,
      },
      search: "",
      purchase: [],
      purchaseSelect: [],
      purchaseIdSelect: 0,
      totalDevolution: 0,
    };
  },
  created() {
    moment.locale("es");
    this.filter.initial =
      moment().format("L").substr(6, 4) +
      "-" +
      moment().format("L").substr(3, 2) +
      "-" +
      moment().format("L").substr(0, 2);
    this.hoy = this.filter.initial;
    this.filter.end = this.filter.initial;
  },
  computed: {
    cpData() {
      let res = [];
      if (this.search.length >= 4) {
        res = this.purchase.filter((element) =>
          element.provider.includes(this.search.toUpperCase())
        );
      } else {
        res = this.purchase;
      }
      return res;
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtdSearchData: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/process/devolution/purchase/" +
          this.filter.initial +
          "/" +
          this.filter.end,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.purchase = response;
        })
        .catch((errors) => {});
    },
    mtdGetActive: function (payload) {
      let clas = "";
      if (payload.id != this.purchaseIdSelect) {
        clas = "list-group-item list-group-item-secondary mt-2";
      } else {
        clas = "list-group-item list-group-item-primary mt-2";
      }
      return clas;
    },
    mtdSelectProduct: function (payload) {
      this.purchaseSelect = payload;
      this.purchaseIdSelect = payload.id;
    },
    mtdSetSubtotal: function(ind){
      let sub = parseFloat(this.purchaseSelect.purchasedetail[ind].price * this.purchaseSelect.purchasedetail[ind].netoDevolution );
      this.purchaseSelect.purchasedetail[ind].subDevolution = parseFloat(sub).toFixed(2);
    },
    mtdCancel: function () {
      this.purchaseSelect = [];
      this.purchaseIdSelect = 0;
    },
    mtdSave: function () {
      if (this.mtdVerifTotalDevolution()) {
        this.post({
          url:
            this.$store.getters.get__url +
            "/process/devolution/purchase/store/" +
            this.$store.getters.get_campu.id,
          token: this.$store.getters.get__token,
          params: {
            payload:this.purchaseSelect,
            totalDevolution: this.totalDevolution
          },
        })
          .then((response) => {
            if (!response.error) {
              bus.$emit("alert", {
                color: "success",
                message: "Devolución registrada!!",
              });
              this.mtdSearchData();
              this.mtdCancel();
            } else {
              bus.$emit("alert", {
                color: "warning",
                message: response.message,
              });
            }
          })
          .catch((errors) => {});
      } else {
        bus.$emit("alert", {
          color: "warning",
          message: "Digite campos para devolver!",
        });
      }
    },
    mtdVerifTotalDevolution: function () {
      let tot = 0;
      this.purchaseSelect.purchasedetail.forEach((element) => {
        tot += parseFloat(element.subDevolution);
      });
      if (tot > 0) {
        this.totalDevolution = tot;
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>