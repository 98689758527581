<template>
  <div class="container">
    <CRow class="mt-3">
      <CCol xxl="12" xl="12" md="12" sm="12" class="text-center">
        <h4>¿EN QUÉ SEDE DESEA TRABAJAR?</h4></CCol
      >
    </CRow>
    <CRow class="mt-3">
      <CCol xxl="3" xl="3" md="3" sm="12"></CCol>
      <CCol xxl="6" xl="6" md="6" sm="12" class="text-center">
        <div class="d-flex justify-content-around">
          <CCard
            v-for="(item, index) in campus"
            :key="index"
            class="mr-1 card"
            @click="mtdSetCampu(item)"
            color="gradient-info"
            body-wrapper
            text-color="white"
          >
            {{ item.name }}
          </CCard>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "c-home-campus",
  props: {
    campus: {
      type: Array,
      default: [],
    },
  },
  methods: {
    mtdSetCampu: function (payload) {
      this.$emit("mtdSetCampu", payload);
    },
  },
};
</script>

<style scoped>
.card {
  cursor: pointer;
}
</style>
