<template>
  <div>
    <CRow>
      <CCol md="12" lg="12" xl="12" xxl="12" xs="12" sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de proveedores"
          icon="fas fa-user-tag"
          btn_name="Proveedor"
          @mtdBack="mtdBack"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'3%'"
          :buttonEdit="true"
          :buttonDelete="true"
        />
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
      :color="'info'"
      :centered="true"
    >
      <CRow>
        <CCol sm="12">
          <CInput
            label="Documento(RUC)"
            placeholder="Digite documento"
            v-model="provider.document"
            maxlength="11"
            v-on:keypress="isNumber($event)"
            @keyup.enter="mtdSearchDocument"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Razón Social"
            placeholder="Digite razón social"
            v-model="provider.name"
            :disabled="true"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Celular"
            placeholder="Digite celular"
            v-model="provider.celphone"
            maxlength="9"
            v-on:keypress="isNumber($event)"
          />
        </CCol>
        <CCol sm="12">
          <CTextarea
            label="Dirección"
            placeholder="Digite dirección"
            v-model="provider.address"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="'Borrar Proveedor'"
      :show.sync="modal_delete.boo"
      @mtd_commit="mtd_commit"
      :centered="true"
      :item="modal_delete.item"
    />
  </div>
</template>
  
  <script>
const fields = [
  { key: "id", label: "Id", _style: "width:1%" },
  { key: "document", label: "Documento (RUC)", _style: "width:20%;" },
  { key: "name", label: "Razón Social", _style: "width:20%;" },
  { key: "celphone", label: "Celular", _style: "width:20%;" },
  { key: "state", label: "Estado", _style: "width:5%;" },
  // {
  //   key: 'show_details',
  //   label: 'Detalle',
  //   _style: 'min-width:1%'
  // },
];

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  components: { CTableWrapper, CModalForm, cModalDelete },
  data() {
    return {
      prefix: "provider",
      fields,
      data: [],
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      provider: {
        id: "",
        document: "",
        name: "",
        celphone: "",
        address: "",
      },
      modal_delete: {
        boo: false,
        item: [],
      },
    };
  },
  computed: {
    cp_button: function () {
      if (
        this.provider.document != "" &&
        this.provider.name != "" &&
        this.provider.celphone != "" && 
        this.provider.address != ""
      )
        return false;
      return true;
    },
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/provider",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.providers;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nuevo Proveedor")
        : (this.modal.title = "Editar Proveedor");
      action == "store"
        ? (this.provider = {
            id: "",
            document: "",
            name: "",
            celphone: "",
            address: "",
            state: "",
          })
        : (this.provider = this.provider);
    },
    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.provider,
      })
        .then((response) => {
          let color = "success";
          let message = "";
          if (this.modal.action == "store") {
            this.data.push(response[0]);
            message = "REGISTRADO CORRECTAMENTE";
            this.mtd_getdata();
          } else {
            this.data.forEach((element) => {
              if (element.id == response.data[0].id) {
                element.document = response.data[0].document;
                element.name = response.data[0].name;
                element.celphone = response.data[0].celphone;
              }
              message = "EDITADO CORRECTAMENTE";
            });
          }
          this.modal = {
            action: "",
            title: "",
            modal_form: false,
            document: "",
            name: "",
            address: "",
            celphone: "",
          };
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.provider = response;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {});
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.id != response.id) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete = {
            boo: false,
            item: [],
          };
          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {});
    },
    /** api document */
    mtdSearchDocument: function () {
      this.get({
        url:
          this.$store.getters.get__url + "/document/" + this.provider.document,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.boo == 0 || response.boo == 2) {
            this.provider.name= response.razon_social;
            bus.$emit("alert", {
              color: 'success',
              message: "Documento encontrado",
            });
          } else {
            bus.$emit("alert", {
              color: 'danger',
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.btn-search {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}
</style>