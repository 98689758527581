<template>
    <div>
    <CRow class="mt-1">
      <CCol lg="4" xl="4" md="12" sm="12" xs="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-dolly"></i>&nbsp; REPORTE DE DESPACHOS</span>
            <button
              @click="mtdBack"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="6" lg="6">
                <CInput
                  label="Fecha Ini."
                  type="date"
                  v-model="rp_dispatch.f_inicio"
                  @change="form_selected"
                />
              </CCol>
              <CCol xs="12" sm="12" md="6" lg="6">
                <CInput
                  label="Fecha Fin."
                  type="date"
                  v-model="rp_dispatch.f_final"
                  @change="form_selected"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="12">
                <button
                  type="button"
                  style="background: #926cdc"
                  :class="btnClasses"
                  @click="loadTable"
                >
                  <i class="fas fa-search"></i>&nbsp; Buscar
                </button>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol v-if="vue.exp" lg="8" xl="8" md="12" sm="12" xs="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Reporte de despachos"
          icon="fas fa-dolly"
          btn_name="Usuario"
          :size="'md'"
          :button_new="false"
          :actions="true"
          :withActions="'15%'"
          :buttonEdit="false"
          :buttonDelete="false"
          :myButtons="myButtons"
          @mtd_dispatchdetail="mtd_dispatchdetail"
          @mtd_ticket="mtd_ticket"
          :buttonBack="false"
        />
      </CCol>
    </CRow>
    <!--Modal Purchase Detail-->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'xl'"
      :color="'info'"
      :centered="true"
      :title="'Detalles de Despacho'"
      :show_boton="false"
      :show.sync="modal_dispatchdetail.boo"
    >
      <div v-for="(report_dispatch, index) in report_dispatch" :key="index">
        <CRow>            
          <CCol lg="12" xl="12" md="12" sm="12" xs="12">
            <CCard class="mb-0">
              <CButton 
                block 
                color="link" 
                class="text-left shadow-none card-header" 
                @click="accordion = accordion === index ? false : index" 
              >
                <h5 class="m-0"> {{ report_dispatch.name }}</h5>
              </CButton>
              <CCollapse :show="accordion === index">
                <CCardBody>
                  <CRow>
                  <CCol lg="6" xl="6" md="12" sm="12" xs="12">
                    <CRow>
                      <!--<CCol xs="12" sm="12" md="6" lg="6">
                        <CInput
                          label="Merma"
                          placeholder="0"
                          v-model="report_dispatch.merma"
                          :disabled="true"
                        />
                      </CCol>-->
                      <CCol xs="12" sm="12" md="12" lg="12">
                        <CInput
                          label="Total Neto"
                          placeholder="0"
                          v-model="report_dispatch.total_neto"
                          :disabled="true"
                        />
                      </CCol>
                      <!--<CCol xs="12" sm="12" md="6" lg="6">
                        <CInput
                          label="Total"
                          placeholder="0"
                          v-model="report_dispatch.total"
                          :disabled="true"
                        />
                      </CCol>-->
                    </CRow>
                  </CCol>
                  <CCol lg="6" xl="6" md="12" sm="12" xs="12">
                    <CCard>
                      <CCardBody>
                        <CTabs add-tab-classes="mt-1">
                          <CTab active>
                            <template slot="title">
                              Vivo
                            </template>
                            <CRow>
                              <CCol xs="12" sm="12" md="3" lg="3">
                                <CInput
                                  label="Peso Java"
                                  placeholder="0"
                                  v-model="report_dispatch.quantity_jaba"
                                  :disabled="true"
                                />
                              </CCol>
                              <CCol xs="12" sm="12" md="3" lg="3">
                                <CInput
                                  label="Cant. Java"
                                  placeholder="0"
                                  v-model="report_dispatch.peso_jaba"
                                  :disabled="true"
                                />
                              </CCol>
                              <CCol xs="12" sm="12" md="3" lg="3">
                                <CInput
                                  label="Cant. Kg"
                                  placeholder="0"
                                  v-model="report_dispatch.quantity_kg_vivo"
                                  :disabled="true"
                                />
                              </CCol>
                              <CCol xs="12" sm="12" md="3" lg="3">
                                <CInput
                                  label="Neto Vivo"
                                  placeholder="0"
                                  v-model="report_dispatch.total_neto_vivo"
                                  :disabled="true"
                                />
                              </CCol>
                            </CRow>
                          </Ctab>
                          <CTab>
                            <template slot="title">
                              Beneficiado
                            </template>
                            <CRow>
                              <CCol xs="12" sm="12" md="3" lg="3">
                                <CInput
                                  label="Peso Cubeta"
                                  placeholder="0"
                                  v-model="report_dispatch.quantity_cubeta_bene"
                                  :disabled="true"
                                />
                              </CCol>
                              <CCol xs="12" sm="12" md="3" lg="3">
                                <CInput
                                  label="Cant. Cubeta"
                                  placeholder="0"
                                  v-model="report_dispatch.peso_cubeta_bene"
                                  :disabled="true"
                                />
                              </CCol>
                              <CCol xs="12" sm="12" md="3" lg="3">
                                <CInput
                                  label="Cant. kg"
                                  placeholder="0"
                                  v-model="report_dispatch.quantity_kg_bene"
                                  :disabled="true"
                                />
                              </CCol>
                              <CCol xs="12" sm="12" md="3" lg="3">
                                <CInput
                                  label="Neto Bene."
                                  placeholder="0"
                                  v-model="report_dispatch.total_neto_bene"
                                  :disabled="true"
                                />
                              </CCol>
                            </CRow>
                          </Ctab>
                        </CTabs>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
      </div>
    </CModalForm>
  </div>
</template>
<script>
  const fields = [
    { key: "id", label: "Id", _style: "width:3%" },
    //{ key: "purchase", label: "N°. Compra", _style: "width:3%" },
    { key: "dealer", label: "Repartidor",_style: "width:20%;" },
    //{ key: "provider", label: "Proveedor", _style: "width:20%;" },
    { key: "user", label: "Usu. Registra", _style: "width:20%;" },
    { key: "date", label: "Fecha", _style: "width:5%;" },
    { key: "amount", label: "Cantidad", _style: "width:10%;" },
  ];

  import CTableWrapper from "../../components/shared/datatable/Table.vue";
  import CModalForm from "../../components/shared/modals/cModalForm.vue";
  import { mapActions } from "vuex";
  import { bus } from "../../main";
  import moment from "moment";

  export default {
    components: {
      CTableWrapper,
      CModalForm,
    },
    data() {
      return {
        prefix: "process",
        vue: {
          exp: false,
          texto: "",
          estado: 1,
        },
        fields,
        data: [],
        report_dispatch:[],
        myButtons: [
          {
            class: "btn-sm",
            color: "success",
            tooltip: "Ver detalles",
            action: "mtd_dispatchdetail",
            icon: "fas fa-eye",
          },
          {
            class: "btn-sm",
            color: "primary",
            tooltip: "Ver ticket",
            action: "mtd_ticket",
            icon: "fas fa-file",
          },
        ],
        rp_dispatch: {
          f_inicio: null,
          f_final: null,
        },
        modal_dispatchdetail: {
          boo: false,
        },
        // accordion
        collapse: false,
      cardCollapse: true,
      innerCollapse: false,
      accordion: 0,
      };
    },
    computed: {
      btnClasses() {
        return [`btn text-white w-100`];
      },
    },
    created() {
      moment.locale("es");
      this.rp_dispatch.f_inicio =
        moment().format("L").substr(6, 4) +
        "-" +
        moment().format("L").substr(3, 2) +
        "-" +
        moment().format("L").substr(0, 2);
      this.rp_dispatch.f_final = this.rp_dispatch.f_inicio;
      // this.mtd_getdata(this.rp_dispatch.f_inicio, this.rp_dispatch.f_final);
    },
    methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function (f_inicio, f_final) {
      this.get({
        url:
          this.$store.getters.get__url +
          "/process/" +
          f_inicio +
          "/" +
          f_final +
          "/dispatchReport",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.dispatch;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    form_selected: function () {
      this.vue.estado = 0;
    },
    loadTable: function () {
      let f_inicio = this.rp_dispatch.f_inicio;
      let f_final = this.rp_dispatch.f_final;
      if (f_inicio && f_final) {
        this.vue.exp = true;
      } else {
        this.vue.exp = false;
      }
      this.mtd_getdata(f_inicio, f_final);
    },
    mtd_dispatchdetail: function (item) {
      this.get({
        url:
          this.$store.getters.get__url +
          "/process/" +
          item.id +
          "/dispatchDetailReport",
        token: this.$store.getters.get__token,
      }).then((response) => {
        this.modal_dispatchdetail.boo = true;
        this.report_dispatch = response.data.dispatchDetail;
      });
    },
    mtd_ticket: function(item){
      window.open(this.$store.getters.get__url + "/ticket/despacho/"+item.id);
    },
  },
  }
</script>