<template>
  <div class="">
    <HomeMaintenceVue v-if="page == 'home'" @changePage="changePage" />
    <RolesVue class="pt-4" v-if="page == 'roles'" @mtdBack="mtdBack" />
    <UsersVue class="pt-4" v-if="page == 'users'" @mtdBack="mtdBack" />
    <CampusVue class="pt-4" v-if="page == 'campus'" @mtdBack="mtdBack" />
    <ProductsVue class="pt-4" v-if="page == 'products'" @mtdBack="mtdBack" />
    <ProvidersVue class="pt-4" v-if="page == 'providers'" @mtdBack="mtdBack" />
    <ClientVue class="pt-4" v-if="page == 'client'" @mtdBack="mtdBack" />
    <DealersVue class="pt-4" v-if="page == 'dealers'" @mtdBack="mtdBack" />
    <BoxVue class="pt-4" v-if="page == 'boxes'" @mtdBack="mtdBack" />
    <MethodPayments class="pt-4" v-if="page == 'method-payment'" @mtdBack="mtdBack" />
    <BillVue class="pt-4" v-if="page == 'bills'" @mtdBack="mtdBack" />
    <BankVue class="pt-4" v-if="page == 'banks'" @mtdBack="mtdBack" />
    
  </div>
</template>

<script>
import BoxVue from "../../components/maintenance/Box.vue";
import CampusVue from "../../components/maintenance/Campus.vue";
import HomeMaintenceVue from "../../components/maintenance/home.vue";

import ProductsVue from "../../components/maintenance/Products.vue";
import ProvidersVue from "../../components/maintenance/Providers.vue";
import ClientVue from "../../components/maintenance/Client.vue";
import DealersVue from "../../components/maintenance/Dealers.vue";
import MethodPayments from "../../components/maintenance/MethodPayments.vue";


import RolesVue from "../access/roles.vue";
import UsersVue from "../access/users.vue";
import BillVue from '../../components/maintenance/Bill.vue';
import BankVue from '../../components/maintenance/Bank.vue';

export default {
  components: {
    HomeMaintenceVue,
    RolesVue,
    UsersVue,
    CampusVue,
    BoxVue,
    ProductsVue,
    ProvidersVue,
    ClientVue,
    DealersVue,
    MethodPayments,
    BillVue,
    BankVue
  },
  data() {
    return {
      page: "home",
    };
  },
  methods: {
    changePage: function (page) {
      this.page = page;
      // console.log(this.page);
      this.$emit("mtdViewFooter", false, "mantenimiento");
    },
    mtdBack: function () {
      this.page = "home";
      // console.log(this.page);
      this.$emit("mtdViewFooter", true, "mantenimiento");
    },
  },
};
</script>
