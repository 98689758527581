<template>
  <div class="">
    <HomeReportVue v-if="page == 'home'" @changePage="changePage" />
    <reportSale class="pt-1" v-if="page == 'sales'" @mtdBack="mtdBack" />
    <flujoDealer class="pt-1" v-if="page == 'flujo-dealer'" @mtdBack="mtdBack" />
  </div>
</template>

<script>
import HomeReportVue from "../../components/dealer/HomeReport.vue";
import reportSale from "../../components/report/Sales.vue";
import flujoDealer from "../../components/report/FlujoRepartidor.vue";

export default {
  components: {
    HomeReportVue,
    reportSale,
    flujoDealer
  },
  data() {
    return {
      page: "home",
    };
  },
  methods: {
    changePage: function (page) {
      this.page = page;
      // console.log(this.page);
      this.$emit("mtdViewFooter", false, "report");
    },
    mtdBack: function () {
      this.page = "home";
      // console.log(this.page);
      this.$emit("mtdViewFooter", true, "report");
    },
  },
};
</script>
