<template>
  <div>
    <CRow class="mt-3">
      <CCol sm="12" xs="12" md="3" lg="3" xl="3">
        <CInput type="date" label="Desde" v-model="filter.f_inicio"/>
      </CCol>
      <CCol sm="12" xs="12" md="3" lg="3" xl="3">
        <CInput type="date" label="Hasta" v-model="filter.f_final"/>
      </CCol>
      <CCol sm="12" xs="12" md="3" lg="3" xl="3">
        <div style="margin-top:8px">&nbsp;</div>
        <button 
          type="button" 
          style="background: #926cdc"
          :class="btnClasses"
          @click="loadTable"
        >
          <i class="fas fa-search"></i>&nbsp; Buscar
        </button>
      </CCol>
    </CRow>
    <CRow class="mt-3">
      <CCol sm="12" xs="12" md="4" lg="4" xl="2">
        <CWidgetProgressIcon
          :header="'S/' + cpTotCompras"
          text="COMPRAS"
          color="gradient-info"
          inverse
        >
          <CIcon name="cil-cart" height="36" />
        </CWidgetProgressIcon>
      </CCol>
      <CCol sm="12" xs="12" md="4" lg="4" xl="2">
        <CWidgetProgressIcon
          :header="'S/' + cpTotalVentas"
          text="VENTAS"
          color="gradient-success"
          inverse
        >
          <CIcon name="cil-dollar" height="36" />
        </CWidgetProgressIcon>
      </CCol>
      <CCol sm="12" xs="12" md="4" lg="4" xl="2">
        <CWidgetProgressIcon
          :header="'S/' + cpGanancias"
          text="GANANCIAS"
          color="gradient-warning"
          inverse
        >
          <CIcon name="cil-plus" height="36" />
        </CWidgetProgressIcon>
      </CCol>
      <CCol sm="12" xs="12" md="4" lg="4" xl="2">
        <CWidgetProgressIcon
          :header="cpKgDispatch + ' Kg'"
          text="DESPACHADOS"
          color="gradient-primary"
          inverse
        >
          <CIcon name="cil-chartPie" height="36" />
        </CWidgetProgressIcon>
      </CCol>
      <CCol sm="12" xs="12" md="4" lg="4" xl="2">
        <CWidgetProgressIcon
          :header="cpKgSale + ' Kg'"
          text="VENDIDOS"
          color="gradient-info"
          inverse
        >
          <CIcon name="cil-speedometer" height="36" />
        </CWidgetProgressIcon>
      </CCol>
      <CCol sm="12" xs="12" md="4" lg="4" xl="2">
        <CWidgetProgressIcon
          :header="cpKgMerma + ' Kg'"
          text="MERMA"
          color="gradient-danger"
          inverse
        >
          <CIcon name="cil-speech" height="36" />
        </CWidgetProgressIcon>
      </CCol>
    </CRow>
  </div>
</template>
  
  <script>
import { mapActions } from "vuex";
import { bus } from "../../main";
import moment from "moment";

export default {
  name: "c-caja-index-day",
  data() {
    return {
      filter: {
        hoy:null,
        f_inicio: null,
        f_final: null,
      },
      purchase: [],
      charge: [],
      sale: [],
      dispatch: [],
      vue: {
        exp: false,
        texto: "",
        estado: 1,
      },
    };
  },
  computed: {
    btnClasses() {
      return [`btn text-white w-100`];
    },
    cpTotCompras() {
      let to = 0;
      this.purchase.forEach((element) => {
        to += parseFloat(element.total);
      });
      return parseFloat(to).toFixed(2);
    },
    cpTotalVentas() {
      let to = 0;
      this.charge.forEach((element) => {
        to += parseFloat(element.amount);
      });
      return parseFloat(to).toFixed(2);
    },
    cpGanancias() {
      let ganancia =
        parseFloat(this.cpTotalVentas) - parseFloat(this.cpTotCompras);
      return parseFloat(ganancia).toFixed(2);
    },
    cpKgDispatch() {
      let to = 0;
      this.dispatch.forEach((element) => {
        element.detail.forEach((ele) => {
          to += parseFloat(ele.total_neto);
        });
      });
      return parseFloat(to).toFixed(2);
    },
    cpKgSale() {
      let to = 0;
      this.sale.forEach((element) => {
        element.detail.forEach((ele) => {
          to += parseFloat(ele.quantity);
        });
      });
      return parseFloat(to).toFixed(2);
    },
    cpKgMerma() {
      let merma = parseFloat(this.cpKgDispatch) - parseFloat(this.cpKgSale);
      return parseFloat(merma).toFixed(2);
    },
  },
  created() {
    moment.locale("es");
    this.filter.f_inicio =
      moment().format("L").substr(6, 4) +
      "-" +
      moment().format("L").substr(3, 2) +
      "-" +
      moment().format("L").substr(0, 2);
      this.filter.hoy = this.filter.f_inicio;
    this.filter.f_final = this.filter.f_inicio;
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdGetData: function (f_inicio, f_final) {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bead/index/day/" + f_inicio + "/" + f_final + "/" + this.$store.getters.get_campu.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.purchase = response.data.purchase;
          this.charge = response.data.charge;
          this.sale = response.data.sale;
          this.dispatch = response.data.dispatch;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    loadTable: function () {
      let f_inicio = this.filter.f_inicio;
      let f_final = this.filter.f_final;
      if (f_inicio && f_final) {
        this.vue.exp = true;
      } else {
        this.vue.exp = false;
      }
      this.mtdGetData(f_inicio, f_final);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
</style>